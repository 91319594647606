<mat-tab-group
  mat-stretch-tabs="true"
  mat-align-tabs="center"
  dynamicHeight="true"
  [@.disabled]="true"
  (selectedTabChange)="onTabChanged($event)"
  [selectedIndex]="selectedIndex"
>
  @for (tab of tabs; track tab) {
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container *ngTemplateOutlet="tab.headerTemplate"></ng-container>
      </ng-template>
      <ng-container *ngTemplateOutlet="tab.contentTemplate"></ng-container>
    </mat-tab>
  }
</mat-tab-group>
