import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CheckoutService as MsCheckoutService } from '@yol-digital/ms-client';
import { of, switchMap, throwError } from 'rxjs';
import { LanguageService } from 'language';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';
import Api = MsCheckoutService.Api;

@Injectable({
  providedIn: 'root',
})
export class IdCheckService {
  private lang = inject(LanguageService);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private http = inject(HttpClient);
  private api = new Api(this.config.newMicroServiceEndpoint, this.http);

  public startIdCheckSession(checkoutSessionId: string) {
    return this.api.eshop.startIdCheck({ checkoutSessionId }).pipe(
      switchMap(resp => {
        if ('error' in resp) {
          return throwError(() => resp.error);
        }
        return of(`${resp.idcRedirectUrl}?lang=${this.lang.current}`);
      })
    );
  }
}
