import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckoutBasketComponent } from 'checkout-basket';
import { ModalComponent } from 'modal';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-product-basket-modal',
  standalone: true,
  imports: [ModalComponent, CheckoutBasketComponent],
  templateUrl: './product-basket-modal.component.html',
})
export class ProductBasketModalComponent {}
