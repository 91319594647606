import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, exhaustMap, iif, of, Subject, takeUntil, tap } from 'rxjs';

@Directive({
  selector: '[libInputLengthMonitor]',
  standalone: true,
})
export class InputLengthMonitorDirective implements OnInit, OnDestroy {
  @Input()
  public debounceTime: number = 100;
  @Input()
  public numberOfChars: number = 10;
  @Output()
  public onLengthReached: EventEmitter<any>;
  protected emitKeyUpEvent$: Subject<string>;
  protected subscription$: Subject<void>;

  @HostListener('keyup', ['$event.target'])
  onKeyup(input: HTMLInputElement) {
    this.emitKeyUpEvent$.next(input.value);
  }

  constructor() {
    this.debounceTime = 500;
    this.onLengthReached = new EventEmitter<any>();
    this.emitKeyUpEvent$ = new Subject<any>();
    this.subscription$ = new Subject<void>();
  }

  ngOnInit() {
    this.emitKeyUpEvent$
      .pipe(
        takeUntil(this.subscription$),
        exhaustMap(value => iif(() => value.length === this.numberOfChars, of(value), of(false))),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(val => this.onLengthReached.emit(val))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
