<div class="md:overflow-x-auto md:mt-8 md:w-4/5 2xl:w-2/3 md:mx-auto">
  <lib-accordion>
    @for (infoCard of informationCards; track infoCard) {
      <lib-accordion-item [isOpen]="!isMobileView" [isToggleEnabled]="isMobileView">
        <div header class="flex justify-between font-bold text-[--color-gray-1]">
          <ngx-dynamic-hooks
            [content]="['checkout_confirmation', infoCard.value + '_title'] | translate"
            [options]="{ sanitize: false }"
          ></ngx-dynamic-hooks>
        </div>
        <div
          class="flex items-center sm:space-x-5 space-x-0 text-justify mb-8"
          [ngClass]="{ 'flex-row': !isMobileView, 'flex-col': isMobileView }"
        >
          <div>
            <h6 class="text-gray-1 mt-4">
              <ngx-dynamic-hooks
                data-source="prismic"
                [content]="['checkout_confirmation', infoCard.value + '_content'] | translate"
                [options]="{ sanitize: false }"
              >
              </ngx-dynamic-hooks>
            </h6>
          </div>
          @if (infoCard.hasCta) {
            <a
              [href]="['checkout_confirmation', infoCard.ctaHref] | translate"
              role="button"
              class="text-slate-600 hover:bg-slate-200 mt-4 text-sm px-4 py-2 border w-96 max-w-[90%] h-9 border-slate-300 rounded-full text-center"
            >
              {{ ['checkout_confirmation', infoCard.ctaButtonName] | translate }}
            </a>
          }
        </div>
      </lib-accordion-item>
    }
  </lib-accordion>
</div>
