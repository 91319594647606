import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from 'translate';

@Injectable({
  providedIn: 'root',
})
export class TitleResolver implements Resolve<string> {
  private translateService = inject(TranslateService);

  resolve(route: ActivatedRouteSnapshot): string | Observable<string> | Promise<string> {
    return this.translateService.getTranslation(['title', route.url[0].path]);
  }
}
