<div class="mt-5">
  <h3 class="text-center text-3xl mb-2.5">{{ ['pos_checkout_contract_signature', 'header_title'] | translate }}</h3>
  @if (orderStatus()?.status) {
    <div class="text-center mt-20">
      {{ ['pos_checkout_contract_signature', 'order_already_completed'] | translate }}
    </div>
  } @else if (this.signatureSent()) {
    <div class="text-center mt-20">
      <ngx-dynamic-hooks
        [content]="['pos_checkout_contract_signature', 'signature_sent_message'] | translate"
        [options]="{ sanitize: false }"
      ></ngx-dynamic-hooks>
    </div>
  } @else {
    <p class="text-center text-sm p-5">
      {{
        ['pos_checkout_contract_signature', 'customer_sign_contract_subtitle']
          | translate
          | replaceToken: { firstName: firstName }
      }}
    </p>
    <lib-list-view [listItems]="listItems()" />
    <div class="lg:max-w-3xl mx-10 lg:mx-auto mt-10">
      <lib-signature-pad (hasSignature)="setHasSignature($event)">
        <button (click)="addSignature()" class="button primary-gradient min-w-72 mt-8">
          {{ ['pos_checkout_contract_signature', 'send_button_text'] | translate }}
        </button>
      </lib-signature-pad>
    </div>
  }
</div>
