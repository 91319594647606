import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { catchError, EMPTY, finalize } from 'rxjs';
import { BrandService } from 'brand';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutSessionService } from 'checkout-session';
import { FeatureFlagService } from 'feature-flag';
import { FormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { Promotion } from 'interfaces';
import { PrismicLibService } from 'prismic';
import { PCProduct } from 'product';
import {
  ProductBoxBase,
  ProductBoxPriceComponent,
  TableDataProductsComponent,
  TableHomeInternetComponent,
  TableMobileComponent,
  TableTvComponent,
} from 'product-box';
import { PromotionBannerComponent } from 'promotion-banner';
import { TranslatePipe } from 'translate';
import { CurrencyFormatterPipe } from 'utils';

@Component({
  selector: 'lib-checkout-basket',
  standalone: true,
  imports: [
    CommonModule,
    TranslatePipe,
    PromotionBannerComponent,
    TableHomeInternetComponent,
    TableDataProductsComponent,
    TableMobileComponent,
    TableTvComponent,
    CurrencyFormatterPipe,
    SvgComponent,
    ProductBoxPriceComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    MatInputModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './checkout-basket.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBasketComponent extends ProductBoxBase {
  checkoutSessionService = inject(CheckoutSessionService);
  featureFlagService = inject(FeatureFlagService);
  checkoutBaseService = inject(CheckoutBaseService);
  prismicLibService = inject(PrismicLibService);
  product = signal<PCProduct>(null);
  promotion = signal<Promotion>(null);
  productItem = computed(() => {
    return {
      product: this.product(),
      promotion: this.promotion(),
    };
  });
  contractDurationText = computed(() => {
    this.checkoutSessionService.newPromoCodeApplied();
    return this.checkoutSessionService.lockingPeriod === 0
      ? this.translateService.getTranslation(['checkout', 'no_contract_duration'])
      : `${this.checkoutSessionService.lockingPeriod.toString()} ${this.translateService.getTranslation([
          'checkout',
          'months',
        ])}`;
  });
  oneTimeCost = computed(() => {
    this.checkoutSessionService.newPromoCodeApplied();
    if (this.checkoutSessionService.activationFeeDiscount) {
      const costDiff = this.checkoutSessionService.activationFee - this.checkoutSessionService.activationFeeDiscount;
      return costDiff.toString();
    }
    return this.checkoutSessionService.activationFee?.toString();
  });
  public isYallo = inject(BrandService).isYallo();
  public promoCodeForm = new FormGroup({
    promoCode: new FormControl(null, []),
  });
  public loadingPrice = signal(false);

  public get promoCodeControl() {
    return this.promoCodeForm.get('promoCode');
  }

  public get showPromoCodeInput() {
    return this.featureFlagService.isOn('promoCode-feature');
  }

  constructor() {
    super();
    effect(
      () => {
        if (this.checkoutSessionService.newPromoCodeApplied() || !this.product()) {
          this.populateAndPromotion();
        }
      },
      { allowSignalWrites: true }
    );
  }

  private populateAndPromotion() {
    this.prismicLibService.getProduct(this.checkoutSessionService.productCode).then(product => {
      this.product.set(product);
    });
    this.promotion.set(this.checkoutSessionService.productPromotion);
  }

  setWrongPromoCode() {
    this.promoCodeControl.enable();
    this.promoCodeControl.setErrors({ promoNotFound: true });
    return EMPTY;
  }

  addNewPromoCode() {
    if (!this.promoCodeControl.value) return;
    this.loadingPrice.set(true);
    this.promoCodeControl.disable();

    this.checkoutBaseService
      .addNewPromoCode(this.promoCodeControl.value)
      .pipe(
        catchError(() => {
          return this.setWrongPromoCode();
        }),
        finalize(() => {
          this.loadingPrice.set(false);
        })
      )
      .subscribe(() => {
        this.checkoutSessionService.newPromoCodeApplied.set(this.promoCodeControl.value);
        this.promoCodeControl.enable();
      });
  }
}
