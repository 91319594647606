@if (!existingCustomer || showFields.scanIdCta) {
  <form [formGroup]="formGroup">
    @if (showScanId) {
      <h3 class="text-black mb-2 section-title">
        {{ ['checkout_details', 'scan_id_section_title'] | translate }}
        <span
          class="ml-4"
          [ngClass]="{
            '!text-[--color-primary]	': identificationDocument?.scanStatus === 'success',
            '!text-[--color-red]	': identificationDocument?.scanStatus === 'failed',
          }"
        >
          {{ scanIdStatusUpdated }}
        </span>
      </h3>
      <div class="flex flex-col gap-4 mb-12">
        <h6 class="label font-medium">
          <ngx-dynamic-hooks [content]="scanInfoText" [options]="{ sanitize: false }"></ngx-dynamic-hooks>
        </h6>
        @if (!identificationDocument?.scanStatus) {
          <div class="flex rounded-2xl justify-center w-full md:mx-auto mt-4 h-12 md:h-[77px]">
            <button
              class="shadow-md hover:shadow-xl text-gray-1 font-bold flex flex-1 flex-col border border-solid border-[--color-gray-3] justify-center items-center text-sm lg:text-lg rounded-l-2xl"
              [ngClass]="{
                '!text-[--color-primary] bg-white !shadow-inner': scanIdNowOrLater.value === 'later',
              }"
              (click)="toggleScanIDOption('later')"
              data-testid="scan-id-later-button"
            >
              {{ ['checkout', 'scan_id_later'] | translate }}
            </button>
            <button
              class="shadow-md hover:shadow-xl text-gray-1 border border-solid border-[--color-gray-3] font-bold flex flex-1 flex-row justify-center items-center text-sm lg:text-lg rounded-r-2xl"
              [ngClass]="{
                '!text-[--color-primary] bg-white !shadow-inner': scanIdNowOrLater.value === 'now',
              }"
              (click)="toggleScanIDOption('now')"
              data-testid="scan-now-button"
            >
              <span>{{ ['checkout', 'scan_id_now'] | translate }}</span>
              <lib-svg class="p-1.5 normal-plus [&_svg]:fill-[--color-gray-1]" svg="fontawesome/sign-out"></lib-svg>
            </button>
          </div>
        }
        @if (identificationDocument?.scanStatus) {
          <lib-button-form-field [disabled]="startIdCheckButtonDisabled" [theme]="['black']" (click)="startIdCheck()">
            <span class="label font-bold">{{ scanButtonText }}</span>
          </lib-button-form-field>
        }
      </div>
    }
    @if (!showScanId || scanIdNowOrLater.value === 'later' || identificationDocument?.scanStatus) {
      <div>
        <h3 class="text-black mb-2 section-title" id="identification">
          {{ ['checkout_details', 'identification'] | translate }}
        </h3>
        @if (!existingCustomer) {
          <lib-radio-button-form-field
            class="mb-2"
            [options]="userTitles"
            formControlName="title"
            (onOptionChange)="selectUserTitle($event)"
          >
          </lib-radio-button-form-field>
        }
        <div class="lg:grid lg:grid-cols-2 lg:gap-x-6">
          @if (!existingCustomer && showFields.firstName) {
            <lib-input-form-field
              class="w-full"
              label="{{ ['checkout_details', 'first_name_label'] | translate }}"
              type="text"
              formControlName="firstName"
              (onBlur)="emitEvent(this.firstName, 'first_name')"
              placeholder="{{ ['checkout_details', 'enter_first_name'] | translate }}"
              data-testid="first-name"
            >
            </lib-input-form-field>
          }
          @if (!existingCustomer && showFields.lastName) {
            <lib-input-form-field
              class="w-full"
              label="{{ ['checkout_details', 'last_name_label'] | translate }}"
              type="text"
              formControlName="lastName"
              (onBlur)="emitEvent(this.lastName, 'last_name')"
              placeholder="{{ ['checkout_details', 'enter_last_name'] | translate }}"
              data-testid="last-name"
            >
            </lib-input-form-field>
          }
          @if (!existingCustomer && showFields.dateOfBirth) {
            <lib-date-picker-form-field
              (onBlur)="onIdentificationBlur('birth date', dateOfBirth)"
              class="w-full"
              label="{{ ['checkout_details', 'date_of_birth_label'] | translate }}"
              type="text"
              formControlName="dateOfBirth"
              placeholder="{{ ['checkout_details', 'enter_birth_date'] | translate }}"
              [maxDate]="dateOfBirthOptions.maxDateOfBirth"
              [minDate]="dateOfBirthOptions.minDateOfBirth"
              [startCalendarDateAt]="startBirthdateDateAt"
              data-testid="date-of-birth"
            >
            </lib-date-picker-form-field>
          }
          @if (showFields.nationality) {
            <lib-nationality-field
              [form]="formGroup"
              (onOptionSelected)="emitEvent(this.nationality, 'nationality')"
            ></lib-nationality-field>
          }
          @if (showFields.idType) {
            <lib-id-type-field
              [productCategory]="productInformation.productSpecCategory | titlecase"
              [form]="formGroup"
              (onOptionSelected)="emitEvent(this.idType, 'id_type')"
            ></lib-id-type-field>
          }
          @if (showFields.idNumber) {
            <lib-input-form-field
              class="w-full"
              label="{{ ['checkout_details', 'id_number_label'] | translate }}"
              type="text"
              formControlName="idNumber"
              placeholder="{{ ['checkout_details', 'enter_id_number'] | translate }}"
            >
            </lib-input-form-field>
          }
          @if (showFields.idExpiryDate) {
            <lib-date-picker-form-field
              class="w-full"
              label="{{ ['checkout_details', 'id_expiry_date_label'] | translate }}"
              type="text"
              formControlName="idExpiryDate"
              placeholder="{{ ['checkout_details', 'enter_id_expiry_date'] | translate }}"
              [maxDate]="maxIdExpiryDate"
              [minDate]="minIdExpiryDate"
              [startCalendarDateAt]="startIdExpiryDateAt"
            >
            </lib-date-picker-form-field>
          }
        </div>
      </div>
    }
  </form>
}
