<div class="sticky top-0 left-0 z-50 h-16 w-full flex kitsune-nav-bar">
  <div class="flex flex-nowrap justify-between items-center xl:container w-full px-3 xl:px-4">
    <div class="flex xl:z-10 h-full">
      <div [ngClass]="{ 'mr-[1rem]': !!logo }">
        @if (logo) {
          <div class="h-full">
            <a [href]="logoLink" (click)="showLeaveCheckoutAlert()">
              <img [src]="logo.url" [alt]="logo.alt" class="w-full h-full" />
            </a>
          </div>
        } @else {
          <a [href]="logoLink" (click)="showLeaveCheckoutAlert()">
            <lib-svg
              svg="brand/logo"
              class="extra-large"
              [ngClass]="{ 'logo-lebara ': brandService.isLebara(), 'logo white': brandService.isYallo() }"
            ></lib-svg>
          </a>
        }
      </div>
    </div>

    <div class="flex justify-center items-center">
      @if (showBasket) {
        <lib-checkout-basket-cta
          [hasBackupPromotionApplied]="hasBackupPromoApplied"
          [inverse]="true"
        ></lib-checkout-basket-cta>
      }
      <lib-language-selector class="mr-3" [collapseOnMobile]="true"> </lib-language-selector>
      @if (showLoginButton()) {
        <div
          class="round-box h-12 w-12 md:w-auto md:max-w-[10rem] xl:max-w-[8rem] flex justify-center items-center xl:mr-0 px-2 cursor-pointer"
          data-testid="login-button"
        >
          @if (existingCustomer) {
            <a
              (click)="navigateToAccountPage()"
              data-testid="account-button"
              class="flex items-center justify-evenly max-w-full"
            >
              <div class="flex items-center max-w-full">
                <lib-svg class="white md:mr-1 normal-plus" svg="fontawesome/user"></lib-svg>
                @if (username) {
                  <p class="hidden md:block text-white font-bold text-sm xl:text-base truncate">
                    {{ username }}
                  </p>
                }
              </div>
            </a>
          } @else {
            <a (click)="openLoginModal()" class="flex items-center justify-evenly">
              <div class="flex items-center">
                <lib-svg class="white md:mr-1 normal-plus hidden md:block" svg="fontawesome/user"></lib-svg>
                <p class="text-white font-bold text-sm xl:text-base">
                  {{ ['login', 'login_button_label'] | translate }}
                </p>
              </div>
            </a>
          }
        </div>
      }
    </div>
  </div>
</div>
