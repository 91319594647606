<div class="overflow-y-hidden mt-8">
  <div class="flex mb-2">
    <p class="field-label text-[--color-gray]">
      {{ ['checkout_phone_number', 'my_new_number_text'] | translate }}
    </p>
  </div>
  <div class="flex items-center">
    <button (click)="changeNewNumber()" class="flex items-center">
      <span id="newNumber" class="field-text text-[--color-dark-gray]">{{ newNumber }}</span>
      @if (!newNumberLoading) {
        <lib-svg class="ml-2 [&_svg]:stroke-[--color-primary]" svg="refresh"></lib-svg>
      }
    </button>
    @if (newNumberLoading) {
      <img src="/resources/small-spinner.gif" class="h-5 ml-2" />
    }
  </div>
</div>
