import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutDetailsComponent } from 'checkout-details';
import { CheckoutSessionService } from 'checkout-session';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CheckoutDetailsComponent, TranslatePipe],
  host: {
    class: 'flex flex-col flex-1',
  },
})
export class DetailsComponent {
  private checkoutSessionService = inject(CheckoutSessionService);
  public checkoutBaseService = inject(CheckoutBaseService);
  firstNameLoggedInUser = this.checkoutSessionService.account?.ownerDetails?.firstName;

  get isAuthenticated() {
    return this.checkoutSessionService.isAuthenticated;
  }

  public callOpenLoginModal() {
    this.checkoutBaseService.openLoginModal({
      existingUser: false,
      email: this.checkoutSessionService.contactEmail,
      disableMfaOnboarding: true,
    });
  }
}
