<div
  [ngClass]="inverse() ? 'border-gray-200' : 'border-[--color-blue-darkened]'"
  class="group relative border-2 rounded-full border-opacity-45 h-12 w-12 flex justify-center items-center mr-9 cursor-pointer z-10"
>
  <div
    #refEl
    [ngClass]="{
      'group-hover:animate-scale-bounce-sm': !hasBackupPromotionApplied,
      'group-hover:animate-scale-bounce-lg': hasBackupPromotionApplied,
      'animate-scale-bounce-lg': hasBackupPromotionApplied,
      'animate-scale-bounce-sm': !hasBackupPromotionApplied,
    }"
    (animationend)="removeClass(refEl)"
    class="table absolute -top-2 -right-1.5 bg-color-red text-[white] rounded-full text-xs h-4 w-[18px]"
  >
    <span class="table-cell align-middle text-center font-extrabold">1</span>
  </div>
  <a (click)="openProductBasketModal()" class="flex items-center justify-evenly bg">
    <div class="flex" data-testid="basket-button-open">
      <lib-svg [ngClass]="[inverse() ? 'white' : 'blue', 'medium']" svg="shopping-cart"></lib-svg>
    </div>
  </a>
</div>
