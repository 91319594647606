import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { SvgComponent } from 'icon';
import { ModalsService } from 'modal';
import { CheckoutBasketModalComponent } from '../checkout-basket-modal/checkout-basket-modal.component';

@Component({
  selector: 'lib-checkout-basket-cta',
  standalone: true,
  imports: [CommonModule, SvgComponent, NgClass],
  templateUrl: './checkout-basket-cta.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutBasketCtaComponent {
  hasBackupPromotionApplied = input(false);
  inverse = input(false);
  private modalsService = inject(ModalsService);

  openProductBasketModal() {
    this.modalsService.openDialog(CheckoutBasketModalComponent);
  }

  removeClass(el: HTMLElement) {
    el.classList.remove('animate-scale-bounce-lg');
    el.classList.remove('animate-scale-bounce-sm');
  }
}
