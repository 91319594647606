import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SvgComponent } from 'icon';
import { ModalComponent } from 'modal';
import { TranslatePipe } from 'translate';

interface ModalData {
  oto_id_modal_header_image?: {
    url: string;
    alt?: string;
  };
  oto_id_modal_title?: string;
  oto_id_modal_content_body?: string;
  oto_id_modal_content_footer?: string;
}

@Component({
  templateUrl: './oto-id-modal.component.html',
  standalone: true,
  imports: [ModalComponent, TranslatePipe, SvgComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtoIdModalComponent {
  modalData = inject<ModalData>(MAT_DIALOG_DATA);
}
