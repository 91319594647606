import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutConfirmationComponent } from 'checkout-confirmation';
import { CheckoutSessionService } from 'checkout-session';
import { LanguageService } from 'language';
import { ModalsService } from 'modal';
import { TranslatePipe } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  standalone: true,
  imports: [CheckoutConfirmationComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationComponent {
  private checkoutSessionService = inject(CheckoutSessionService);
  private checkoutBaseService = inject(CheckoutBaseService);
  private browserService = inject(BrowserService);
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private languageService = inject(LanguageService);
  private modalsService = inject(ModalsService);

  ctaAction() {
    if (this.checkoutSessionService.isAuthenticated) {
      this.navigateToAccountPage();
    } else {
      this.openLoginModal();
    }
  }

  private navigateToAccountPage() {
    this.checkoutBaseService.addAnalyticsEvent('to_your_account');
    this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}/account`);
  }

  openLoginModal() {
    this.checkoutBaseService.addAnalyticsEvent('to_your_account');
    this.checkoutBaseService.openLoginModal({
      existingUser: false,
      email: this.checkoutSessionService.contactEmail,
      redirectToSelfcare: true,
    });
  }
}
