import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrderHandlerService } from '@yol-digital/ms-client';
import { catchError, EMPTY, map, mergeMap, of } from 'rxjs';
import { CheckoutMsResponse, CheckoutMsStateResponse } from 'checkout-base';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard {
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private router = inject(Router);
  private checkoutDataAccessService = inject(CheckoutDataAccessService);
  private checkoutSessionService = inject(CheckoutSessionService);
  private checkoutFlowService = inject(CheckoutFlowService);
  private loadingService = inject(CheckoutLoadingService);
  private translateService = inject(TranslateService);
  private browserService = inject(BrowserService);
  private toastService = inject(ToastService);
  private languageService = inject(LanguageService);
  private redirectService = inject(RedirectService);

  public detailsGuard(checkoutSessionId: string) {
    this.loadingService.showSkeletonLoading();
    return this.checkForOrderSubmitted(checkoutSessionId).pipe(
      map(({ status }: OrderHandlerService.OrderStatusRes) => {
        if (!status) {
          return true;
        }

        return this.router.parseUrl(`${checkoutSessionId}/confirmation`);
      })
    );
  }

  public confirmationGuard(checkoutSessionId: string) {
    return this.checkForOrderSubmitted(checkoutSessionId).pipe(
      map(({ status }: OrderHandlerService.OrderStatusRes) => {
        if (status) {
          return true;
        }

        return this.router.parseUrl(`${checkoutSessionId}/details`);
      })
    );
  }

  private checkForOrderSubmitted(checkoutSessionId: string) {
    return this.checkoutDataAccessService.retrieveOrderStatus(checkoutSessionId).pipe(
      catchError(err => {
        if (!err?.wasCaught) {
          console.error(err);
          this.toastService.add(this.translateService.getTranslation(['checkout', 'invalid_session_id']), false, 2000);
        }
        setTimeout(
          () => this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}`),
          2000
        );
        return EMPTY;
      })
    );
  }

  public wrongRouteGuard() {
    const message = this.translateService.getTranslation(['checkout', 'error_create_session']);
    this.toastService.add(message, false, 2000);
    setTimeout(() => this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}`), 2000);
  }

  public phoneNumberGuard(checkoutSessionId: string) {
    this.loadingService.showSkeletonLoading();

    if (this.checkoutSessionService.session) {
      return of(true);
    }

    this.checkoutSessionService.updateSession({ checkoutSessionId });
    const getCheckoutDetailsByIdCall$ = this.checkoutDataAccessService.checkoutDetailsById();
    const getCheckoutCurrentStateCall$ = this.checkoutDataAccessService.currentStateById();

    let newUserNotReady = true;
    let loggedInUser = false;

    return getCheckoutDetailsByIdCall$.pipe(
      mergeMap((res: CheckoutMsResponse) => {
        const productHasStep = this.checkoutFlowService.productHasStep(
          res.cartData?.products[0]?.productSpecClass,
          res.cartData?.products[0]?.productSpecFamily,
          'phone-number'
        );
        if (!productHasStep) {
          return this.redirectService.redirectTo(`${checkoutSessionId}/details`);
        }

        loggedInUser = !!res.billingCustomerDetails?.accountId;
        newUserNotReady =
          !loggedInUser && !(res.billingCustomerDetails?.isConfirmed && res.billingCustomerDetails?.billingAddress);

        if (newUserNotReady) return this.redirectService.redirectTo(`${checkoutSessionId}/details`);

        return getCheckoutCurrentStateCall$;
      }),
      mergeMap((state: CheckoutMsStateResponse) => {
        const sessionMissingUpdateAddress = (state.stepDetails as { shipmentAddress: string })?.shipmentAddress;
        if (loggedInUser && sessionMissingUpdateAddress) {
          return this.redirectService.redirectTo(`${checkoutSessionId}/details`);
        }
        return of(true);
      })
    );
  }
}
