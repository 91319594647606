import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Params, UrlTree } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { SelfcareService } from '@yol-digital/ms-client';
import { catchError, concatMap, EMPTY, forkJoin, from, map, mergeMap, Observable, of, switchMap, tap } from 'rxjs';
import { CheckoutFlow } from 'checkout-flow';
import { CHECKOUT_ERRORS, DetailsFields } from 'checkout-utils';
import { PCProduct } from 'product';
import { DATE_MS_FORMAT } from 'utils';
import { CheckoutBaseService, CheckoutMsResponse } from '../checkout-base.service';

@Injectable({
  providedIn: 'root',
})
export class EshopCheckoutBaseService extends CheckoutBaseService {
  protected location = inject(Location);

  constructor() {
    super();
    this.checkoutFlowService.finishOrder$.subscribe((activePhoneNumberTab: number) => {
      this.finishOrder(activePhoneNumberTab);
    });
  }

  public startSession(productCode: string, params: Params): Observable<UrlTree | boolean> {
    this.checkoutLoadingService.showSkeletonLoading();
    const lockingPeriodStr: string = params['lockingPeriod'];
    const lineCheckId: string = params['lineCheckId'];
    const fallbackUrl: string = params['fallbackUrl'];
    const promotionCodeFromUrl: string = params['promotion'];

    const getTrackingCookiesCall$ = this.analyticsService.getTrackingCookies(null);
    const loadProductDataCall$ = this.productService.getPCProductByCode(productCode);

    const createSessionCall$ = forkJoin([getTrackingCookiesCall$, loadProductDataCall$]).pipe(
      switchMap(([trackingCookies, loadProductRes]) => {
        const sessionReq = this.createSessionRequest(trackingCookies, params);

        const defaultPromotion = loadProductRes.defaultPromotion?.code;

        return this.checkoutDataAccessService.createSessionId(sessionReq).pipe(
          tap(({ checkoutSessionId }: { checkoutSessionId: string }) =>
            this.checkoutSessionService.updateSession({ checkoutSessionId })
          ),
          switchMap(() =>
            this.getBackupPromotion(promotionCodeFromUrl, defaultPromotion).pipe(
              switchMap((backupOnlinePromotions: PrismicDocument[] | null) =>
                this.setPromotion(loadProductRes, backupOnlinePromotions, promotionCodeFromUrl, defaultPromotion)
              ),
              tap(({ backupPromotionApplied }) => {
                this.lineCheckRefId = lineCheckId;
                this.productData = loadProductRes;
                this.product = PCProduct.fromMS(loadProductRes);
                this.backupPromotionApplied.set(backupPromotionApplied);
                if (backupPromotionApplied) {
                  this.addAnalyticsEvent('backup_promotion_applied');
                }
              })
            )
          )
        );
      })
    );

    const addProductCall$ = createSessionCall$.pipe(
      mergeMap(({ promotion, promotionCode, backupPromotionApplied }) => {
        const lockingPeriod = this.getLockingPeriod(lockingPeriodStr, promotion, backupPromotionApplied);
        return this.addProduct(productCode, lockingPeriod, promotionCode);
      })
    );

    const setLineCheck$ = addProductCall$.pipe(
      mergeMap(() => {
        if (this.productData.productSpecClass == 'HFC' || this.productData.productSpecClass == 'FIBER') {
          const defaultWishDate = new Date();
          defaultWishDate.setDate(defaultWishDate.getDate() + (this.productData.productSpecClass == 'HFC' ? 3 : 15));
          if (!lineCheckId) {
            this.displayErrorMessage(CHECKOUT_ERRORS.LINECHECK_REF_ID_MISSING);
            setTimeout(() => {
              if (fallbackUrl) this.browserService.redirect(fallbackUrl);
              else this.location.back();
            }, 2000);
          }
          return this.addLineCheck(new DatePipe('en-US').transform(defaultWishDate, DATE_MS_FORMAT));
        }
        return of(null);
      })
    );

    const checkUserAuthentication$ = setLineCheck$.pipe(
      mergeMap(() => from(this.authService.getToken())),
      mergeMap(token => {
        const currentUserType = token ? 'existing_user' : 'new_user_funnel';
        this.addAnalyticsEcommerceEvent('begin_checkout', undefined, currentUserType);
        return of(null);
      }),
      catchError(err => {
        this.handleError(err, () => {
          setTimeout(() => {
            if (fallbackUrl) this.browserService.redirect(fallbackUrl);
            else {
              this.location.back();
            }
            this.checkoutLoadingService.hideLoading();
          }, 2000);
        });
        return EMPTY;
      }),
      map(() => {
        history.replaceState(
          null,
          null,
          `/${this.languageService.current}/${this.checkoutSessionService.sessionId}/details`
        );

        const urlTree = [this.languageService.current, this.checkoutSessionService.sessionId, 'details'];
        return this.router.createUrlTree(urlTree);
      })
    );

    return checkUserAuthentication$;
  }

  public loadSession(
    checkoutSessionId: string,
    routePath: string,
    callHideLoadingAtTheEnd = true
  ): Observable<CheckoutMsResponse> {
    // dont show skeleton when coming from order submit(from any page) to confirmation
    const hasPreviousNavigation = this.router.lastSuccessfulNavigation?.previousNavigation;
    const showSkeleton = hasPreviousNavigation || routePath !== 'confirmation';
    if (showSkeleton) {
      this.checkoutLoadingService.showSkeletonLoading();
    }
    this.checkoutSessionService.updateSession({ checkoutSessionId });
    const getCheckoutDetails$ = this.checkoutDataAccessService
      .checkoutDetailsById()
      .pipe(tap(res => this.checkoutSessionService.updateSession(res as CheckoutMsResponse)));

    const getCustomerAccountCall$ = this.userService.getAccount();

    return forkJoin([getCheckoutDetails$, getCustomerAccountCall$]).pipe(
      switchMap(([checkoutDetails, account]: [CheckoutMsResponse, SelfcareService.AccountResp]) => {
        this.checkoutSessionService.updateAccount(account);
        this.checkoutSessionService.updateSession(checkoutDetails);

        if (checkoutDetails.orderStatus === 'IN_CREATION') {
          if (checkoutDetails.billingCustomerDetails?.accountId) {
            const sameAccount =
              this.checkoutSessionService.account?.accountId === checkoutDetails.billingCustomerDetails?.accountId;
            const redirectUrl = this.browserService.getOrigin();
            const redirectPath = `${this.config.websiteUrl}/${this.languageService.current}/auth/login?redirectPath=${redirectUrl}/${this.languageService.current}/${this.checkoutSessionService.sessionId}/details`;

            if (!sameAccount) {
              const logout$ = this.checkoutSessionService.account?.accountId ? this.authService.logout() : of(null);
              return logout$.pipe(
                tap(() => {
                  this.browserService.redirect(redirectPath);
                })
              );
            }
          } else {
            const setPersonalIdAndFetchDetails$ =
              account && !checkoutDetails.billingCustomerDetails?.accountId
                ? this.checkoutDataAccessService.setPersonalId().pipe(switchMap(() => getCheckoutDetails$))
                : getCheckoutDetails$;
            return setPersonalIdAndFetchDetails$;
          }
        }
        return of(checkoutDetails);
      }),
      switchMap((checkoutDetails: CheckoutMsResponse) =>
        forkJoin([
          of(checkoutDetails),
          this.productService.getPCProductByCode(checkoutDetails?.cartData.products[0].code),
        ])
      ),
      switchMap(([checkoutDetails, productData]) => {
        this.productData = productData;
        this.product = PCProduct.fromMS(this.productData);
        checkoutDetails.cartData.products.forEach(product => {
          this.threatMetrixService.profile(product.tmxSessionId);
        });

        this.lineCheckRefId = checkoutDetails.cartData.products[0]?.lineCheckDetails?.refId;

        this.featureFlagService.setAttributeOverrides({
          checkoutSessionId: this.checkoutSessionService.sessionId,
          productClass: this.checkoutSessionService.productSpecClass,
        });
        this.checkoutFlowService.checkoutFlow = this.checkoutFlowService.checkoutFlowData.find(
          (flow: CheckoutFlow) =>
            flow.productClass == checkoutDetails.cartData.products[0].productSpecClass &&
            flow.productFamily == checkoutDetails.cartData.products[0].productSpecFamily
        );

        if (this.shouldDoIdCheck()) {
          const hasDoneIdCheckInSession = this.checkoutSessionService.idScanSuccessful;
          if (!hasDoneIdCheckInSession) {
            this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_NUMBER);
            this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_EXPIRY_DATE);
          }
        } else {
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.SCAN_ID_CTA);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_TYPE);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.NATIONALITY);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_NUMBER);
          this.checkoutFlowService.checkoutFlow.detailsFieldsToHide.push(DetailsFields.ID_EXPIRY_DATE);
        }

        if (callHideLoadingAtTheEnd) {
          this.checkoutLoadingService.hideLoading();
        }

        return of(checkoutDetails);
      }),
      catchError(err => {
        this.handleError(err, () => {
          setTimeout(() => {
            this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}`);
          }, 2000);
        });
        return EMPTY;
      })
    );
  }

  public handleConfirmCustomerDetailsError(err: HttpErrorResponse) {
    if (err?.error.error === CHECKOUT_ERRORS.CUSTOMER_FOUND_LOGIN_REQUIRED) {
      this.openLoginModal({
        existingUser: true,
        firstName: this.checkoutSessionService.firstName,
        disableMfaOnboarding: true,
      });
      return EMPTY;
    }
    this.handleError(err);
    return EMPTY;
  }

  updateContactDetails(data: { contactNumber: string }) {
    return this.userService.updateContactDetails(data).pipe(
      tap(() => {
        this.updatedSelfcareData = true;
      })
    );
  }

  updateUserEmail(email?: string) {
    return this.userService.updateUserEmail(email).pipe(
      tap(() => {
        this.updatedSelfcareData = true;
      })
    );
  }

  updateUserBillingAddress(data: SelfcareService.UpdateBillingAddressReq) {
    return this.userService.updateUserBillingAddress(data).pipe(
      tap(() => {
        this.updatedSelfcareData = true;
      })
    );
  }

  public logout(redirectUrl?: string): void {
    this.checkoutLoadingService.showSkeletonLoading();
    this.authService
      .logout()
      .pipe(
        switchMap(() => this.handleLoggedOutUser()),
        catchError(err => {
          if (!err?.wasCaught) {
            console.error(err);
            this.toastService.add(this.translateService.getTranslation(['login', 'error', 'generic']), false);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        if (redirectUrl) {
          this.browserService.redirect(redirectUrl);
        } else {
          this.browserService.redirect(`/${this.checkoutSessionService.sessionId}/details`);
        }
      });
  }

  public handleError(err: HttpErrorResponse, cb?: () => void): Observable<never> {
    this.addAnalyticsEvent('error_occurred', {
      error_message: err?.message,
      error_code: err?.error?.error,
      http_status: '' + err?.status,
    });

    if (err?.error?.error === CHECKOUT_ERRORS.PREPAID_MAX_REACHED) {
      this.addAnalyticsEvent('error_credit_check_rejection');
      this.browserService.redirect(
        `${this.config.websiteUrl}/${this.languageService.current}/credit-rejection-too-many-subs`
      );
      return EMPTY;
    }
    this.displayErrorMessage(err?.error?.error, 'checkout_somthing');
    console.error(err);

    if (typeof cb === 'function') {
      cb();
    }
    return EMPTY;
  }

  public finishOrder(phoneNumberActiveTab?: number) {
    this.addAnalyticsEcommerceEvent('add_payment_info');

    if (this.checkoutSessionService.idScanSuccessful) {
      this.addAnalyticsEvent('scan_id_successful');
    }

    if (this.checkoutSessionService.eSim) {
      this.addAnalyticsEvent('esim_activated');
    }

    const creditCheckCall$ =
      this.checkoutSessionService.productSpecCategory === 'PREPAID'
        ? of(null)
        : this.checkoutDataAccessService.doCreditCheck();

    const callGeneratePOA = this.checkoutSessionService.productSpecFamily === 'MOBILE' && phoneNumberActiveTab === 0;

    const POACalls$ = callGeneratePOA
      ? this.generatePoa().pipe(concatMap(() => this.checkoutDataAccessService.addSign('POA_SIGNATURE')))
      : of(null);

    const contractCalls$ = this.generateContract().pipe(
      concatMap(() => this.checkoutDataAccessService.addSign('CONTRACT_SIGNATURE'))
    );

    creditCheckCall$
      .pipe(
        concatMap(() => forkJoin([POACalls$, contractCalls$])),
        tap(() => this.checkoutLoadingService.progressValue$.next(98)),
        concatMap(() => this.checkoutDataAccessService.submitOrder()),
        catchError(err => {
          this.checkoutLoadingService.hideLoading();
          if (!err?.wasCaught) {
            return this.handleSubmitOrderErrors(err);
          }
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.addAnalyticsEcommerceEvent('purchase', this.checkoutSessionService.sessionId || '');

        const urlTree = [this.checkoutSessionService.sessionId, 'confirmation'];

        this.router.navigate(urlTree);
      });
  }

  protected handleSubmitOrderErrors(err: HttpErrorResponse) {
    if (err?.error.error === CHECKOUT_ERRORS.CREDITCHECK_LIGHT_PRODUCT_ONLY) {
      this.addAnalyticsEvent('error_credit_check_rejection');
      this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}/credit-rejection-limit`);
      return EMPTY;
    }

    if (
      err?.error.error === CHECKOUT_ERRORS.INVALID_CREDIT_CHECK_DATA ||
      err?.error.error === CHECKOUT_ERRORS.CREDITCHECK_BAD_CUSTOMER ||
      err?.error.error === CHECKOUT_ERRORS.CREDITCHECK_MANUAL_CHECK_REQUIRED
    ) {
      this.addAnalyticsEvent('error_credit_check_rejection');
      this.browserService.redirect(
        `${this.config.websiteUrl}/${this.languageService.current}/credit-rejection-hard-stop`
      );
      return EMPTY;
    }

    if (err?.error.error === CHECKOUT_ERRORS.CREDITCHECK_ALLOWED_SUB_EXCEEDED) {
      this.addAnalyticsEvent('error_credit_check_rejection');
      this.browserService.redirect(
        `${this.config.websiteUrl}/${this.languageService.current}/credit-rejection-too-many-subs`
      );
      return EMPTY;
    }

    this.handleError(err);
    return EMPTY;
  }
}
