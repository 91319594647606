<div class="flex items-center py-4">
  <div class="flex items-center mr-10">
    <lib-svg [svg]="item().icon" />
    <div class="w-10">
      @if (item().checked) {
        <span class="ml-3 rounded-full bg-[--color-primary-opacity-light] flex items-center justify-center p-1.5">
          <lib-svg [svg]="'check'" class="w-4 [&_svg]:w-full [&_svg]:stroke-[--color-primary]"></lib-svg
        ></span>
      }
    </div>
  </div>
  <p class="flex-1">
    {{ item().title }}
  </p>
  <button class="button secondary size-sm" (click)="item().buttonAction()">{{ item().buttonText }}</button>
</div>
