import { DOCUMENT, NgClass } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { UserService } from 'auth-data-access';
import { BrandService } from 'brand';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutBasketCtaComponent } from 'checkout-basket';
import { CheckoutSessionService } from 'checkout-session';
import { SvgComponent } from 'icon';
import { Image } from 'interfaces';
import { LanguageService } from 'language';
import { LanguageSelectorComponent } from 'menu';
import { ModalsService } from 'modal';
import { NavigationService } from 'navigation';
import { prismicToImage } from 'prismic';
import { TranslatePipe, TranslateService } from 'translate';
import { BrowserService, ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig } from 'utils';
import { ProductBasketModalComponent } from '../modal/product-basket-modal/product-basket-modal.component';
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  standalone: true,
  imports: [SvgComponent, LanguageSelectorComponent, TranslatePipe, NgClass, CheckoutBasketCtaComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements AfterContentChecked {
  private modalsService = inject(ModalsService);
  private browserService = inject(BrowserService);
  private checkoutBaseService = inject(CheckoutBaseService);
  checkoutSessionService = inject(CheckoutSessionService);
  private router = inject(Router);
  brandService = inject(BrandService);
  private navigationService = inject(NavigationService);
  private translateService = inject(TranslateService);
  private languageService = inject(LanguageService);
  private userService = inject(UserService);
  private document = inject<Document>(DOCUMENT);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  @Input() leaveCheckoutAlertMessage?: string;
  username: string;
  logo: Image;
  themeColor: string;
  showBasket: boolean;
  logoLink: string;
  existingCustomer: boolean;
  showLoginButton = input(true);

  get hasBackupPromoApplied() {
    return this.checkoutBaseService.backupPromotionApplied();
  }

  constructor() {
    const router = this.router;
    const cdr = inject(ChangeDetectorRef);

    if (this.navigationService?.customization) {
      this.logo = this.navigationService?.customization?.logo.url
        ? prismicToImage(this.navigationService?.customization?.logo)
        : undefined;
      this.themeColor = this.navigationService?.customization?.themeColor;
      if (this.themeColor) this.turnOnCustomTheme();
    }

    this.showBasket = !this.document.location.pathname.includes('confirmation');
    router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.showBasket = !e.url.includes('confirmation');
      cdr.markForCheck();
    });

    this.userService
      .getAccount()
      .pipe(takeUntilDestroyed())
      .subscribe(account => {
        this.existingCustomer = !!account;
        this.username = account?.ownerDetails?.firstName;
      });
  }

  ngAfterContentChecked() {
    this.logoLink = `${this.config.websiteUrl}/${this.languageService.current}`;
  }

  turnOnCustomTheme() {
    this.document.documentElement.style.setProperty('--color-theme', this.themeColor);
    const customTheme = this.document.body.classList.contains('custom-theme');
    if (!customTheme) {
      this.document.body.classList.add('custom-theme');
    }
  }

  openLoginModal() {
    this.checkoutBaseService.openLoginModal({
      existingUser: false,
      email: this.checkoutSessionService.contactEmail,
      disableMfaOnboarding: true,
    });
  }

  navigateToAccountPage() {
    if (this.showLeaveCheckoutAlert()) {
      this.browserService.redirect(`${this.config.websiteUrl}/${this.languageService.current}/account`);
    }
  }

  openProductBasketModal() {
    this.modalsService.openDialog(ProductBasketModalComponent);
  }

  showLeaveCheckoutAlert() {
    if (!this.router.url.includes('confirmation')) {
      const defaultMessage = this.translateService.getTranslation(['checkout_details', 'leave_checkout_alert_message']);
      const leaveCheckout = confirm(this.leaveCheckoutAlertMessage ?? defaultMessage);
      this.checkoutBaseService.addAnalyticsEvent('checkout_exit_popup', {
        exit_option_selected: leaveCheckout ? 'ok' : 'cancel',
      });
      return leaveCheckout;
    }
    return true;
  }
}
