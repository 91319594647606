import { inject, Injectable, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckoutService, LineCheckService } from '@yol-digital/ms-client';
import { addDays, addMonths, endOfToday, parse, startOfToday, subYears } from 'date-fns';
import { of } from 'rxjs';
import { CheckoutBaseService } from 'checkout-base';
import { UserNeedsLogin } from 'checkout-contact-details';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutSessionService } from 'checkout-session';
import { DetailsFields } from 'checkout-utils';
import {
  dateValidator,
  emailValidator,
  fiberLineGroupValidator,
  fiberLineValidator,
  phoneNumberValidator,
  postCodeValidator,
  textValidator,
  UserTitle,
} from 'form-field';
import { TranslateService } from 'translate';
import { AddressLookupFields, AddressLookupService, DATE_MS_FORMAT } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class CheckoutDetailsService {
  private addressLookupService = inject(AddressLookupService);
  private flowService = inject(CheckoutFlowService);
  private checkoutSessionService = inject(CheckoutSessionService);
  private checkoutBaseService = inject(CheckoutBaseService);
  private translateService = inject(TranslateService);
  // User Detection variables
  public userNeedsToLogin = signal<UserNeedsLogin>({
    value: false,
    clickedNext: false,
  });
  public phoneNumberInvalid = signal(false);
  public isEmailValidForBackend = signal(true);
  public startCalendarActivationDateAt: Date = addDays(startOfToday(), this.isHFC ? 3 : 15);

  get minActivationDate() {
    if (this.isHFC || this.isFiber) return this.startCalendarActivationDateAt;
    return;
  }

  public maxActivationDate: Date = addMonths(endOfToday(), 6);
  public minDateOfBirth: Date = subYears(startOfToday(), 120);
  public maxDateOfBirth: Date = subYears(endOfToday(), 18);
  public addressLookupFields = signal<AddressLookupFields>(null);
  public otoIdFieldFocus = signal(false);
  public activateEsim = signal(false);
  public validateEmail = signal(false);
  public previousInvalidEmail = signal<string>(null);
  detailsForm: FormGroup<{
    contactDetails: FormGroup<{
      email: FormControl;
      contactPhone: FormControl;
    }>;
    identification: FormGroup<{
      scanIdNowOrLater: FormControl;
      title: FormControl;
      firstName: FormControl;
      lastName: FormControl;
      dateOfBirth: FormControl;
      nationality?: FormControl;
      idType?: FormControl;
      idNumber?: FormControl;
      idExpiryDate?: FormControl;
    }>;
  }>;
  wishDateForm: FormGroup<{ wishDate: FormControl }>;
  public addressForm: FormGroup<{ shippingAddress: FormGroup; billingAddress: FormGroup }> = new FormGroup({
    shippingAddress: new FormGroup({
      enableDifferentShippingAddress: new FormControl(false),
      postCode: new FormControl(null, [Validators.required, postCodeValidator]),
      city: new FormControl(),
      street: new FormControl(null, [Validators.required]),
      streetNumber: new FormControl(null, [Validators.required]),
      diffPostboxName: new FormControl(null, [textValidator]),
    }),
    billingAddress: new FormGroup({
      enableDifferentBillingAddress: new FormControl(false),
      postCode: new FormControl(null, [Validators.required, postCodeValidator]),
      city: new FormControl(),
      street: new FormControl(null, [Validators.required]),
      streetNumber: new FormControl(null, [Validators.required]),
      diffPostboxName: new FormControl(null, [textValidator]),
    }),
  });
  public contactDetailsForm = new FormGroup({
    contactPhone: new FormControl(null, [Validators.required, phoneNumberValidator(false)]),
    email: new FormControl(null, [Validators.required, emailValidator]),
  });
  public identificationForm = new FormGroup({
    scanIdNowOrLater: new FormControl<'now' | 'later'>(null),
    title: new FormControl(UserTitle.MR, { nonNullable: true }),
    firstName: new FormControl(null, [Validators.required, textValidator]),
    lastName: new FormControl(null, [Validators.required, textValidator]),
    dateOfBirth: new FormControl(null, [Validators.required]),
  });
  public otoIdForm = new FormGroup(
    {
      enterFiberNumberNowOrLater: new FormControl<'now' | 'later'>('later', [Validators.required]),
      otoId: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [fiberLineValidator]
      ),
    },
    [fiberLineGroupValidator]
  );

  public get billingAddressForm() {
    return this.addressForm.get('billingAddress');
  }

  public get shippingAddressForm() {
    return this.addressForm.get('shippingAddress');
  }

  public get scanIdNowOrLater() {
    return this.identificationForm.get('scanIdNowOrLater');
  }

  public get title() {
    return this.identificationForm.get('title');
  }

  public get firstName() {
    return this.identificationForm.get('firstName');
  }

  public get lastName() {
    return this.identificationForm.get('lastName');
  }

  public get dateOfBirth() {
    return this.identificationForm.get('dateOfBirth');
  }

  public get wishDate() {
    return this.wishDateForm?.get('wishDate');
  }

  public get nationality() {
    return this.identificationForm.get('nationality');
  }

  public get email() {
    return this.contactDetailsForm.get('email');
  }

  public get contactPhone() {
    return this.contactDetailsForm.get('contactPhone');
  }

  public get idType() {
    return this.identificationForm.get('idType');
  }

  public get idNumber() {
    return this.identificationForm.get('idNumber');
  }

  public get idExpiryDate() {
    return this.identificationForm.get('idExpiryDate');
  }

  get otoId() {
    return this.otoIdForm.get('otoId');
  }

  get enterFiberNumberNowOrLater() {
    return this.otoIdForm.get('enterFiberNumberNowOrLater');
  }

  readonly detailsFields = DetailsFields;

  get fieldsToHide() {
    return this.flowService.checkoutFlow?.detailsFieldsToHide;
  }

  get finalUserAddress() {
    if (
      (['MOBILE', 'TV'].includes(this.checkoutSessionService.productSpecFamily) || this.checkoutSessionService.isMBB) &&
      this.billingAddressForm.valid
    ) {
      return {
        postCode: this.billingAddressForm.get('postCode').value,
        streetName: this.billingAddressForm.get('street').value,
        streetNumber: this.billingAddressForm.get('streetNumber').value,
        city: this.billingAddressForm.get('city').value,
        co: this.billingAddressForm.get('diffPostboxName').value,
      };
    }
    return this.checkoutSessionService.installationAddress;
  }

  get isFiber() {
    return this.checkoutSessionService.productSpecClass === 'FIBER';
  }

  get isHFC() {
    return this.checkoutSessionService.productSpecClass === 'HFC';
  }

  public get showAddressForm() {
    return (
      this.isHFC ||
      this.isFiber ||
      this.scanIdNowOrLater.value === 'later' ||
      this.checkoutSessionService.idDocument?.scanStatus ||
      this.fieldsToHide?.includes(this.detailsFields.SCAN_ID_CTA)
    );
  }

  public get finalUserObject() {
    return {
      shipmentAddress: {
        postCode: this.finalUserAddress?.postCode,
        streetName: this.finalUserAddress?.streetName,
        streetNumber: this.finalUserAddress?.streetNumber,
        city: this.finalUserAddress?.city,
        ...(this.finalUserAddress?.co ? { co: this.finalUserAddress.co } : {}),
      },
      billingAddress: {
        postCode: this.finalUserAddress?.postCode,
        streetName: this.finalUserAddress?.streetName,
        streetNumber: this.finalUserAddress?.streetNumber,
        city: this.finalUserAddress?.city,
        ...(this.finalUserAddress?.co ? { co: this.finalUserAddress.co } : {}),
      },
      customerAddress: {
        postCode: this.finalUserAddress?.postCode,
        streetName: this.finalUserAddress?.streetName,
        streetNumber: this.finalUserAddress?.streetNumber,
        city: this.finalUserAddress?.city,
        ...(this.finalUserAddress?.co ? { co: this.finalUserAddress.co } : {}),
      },
    };
  }

  public get invalidDetailsForms() {
    const idTypeIsEmpty = this.idType ? !this.detailsForm.value.identification.idType : false;
    const areAddressesInvalid = this.areAddressesInvalid();

    const isActivationDateInvalid = this.wishDateForm?.invalid;
    const isOtoIdFormInvalid = this.otoIdForm.invalid;
    const mobile = !this.flowService.desktopView;
    const desktop = this.flowService.desktopView;
    return (
      (mobile && this.flowService.activeSubstepIndex === 0 && (this.detailsForm.invalid || idTypeIsEmpty)) ||
      (mobile &&
        this.flowService.activeSubstepIndex === 1 &&
        (areAddressesInvalid || isActivationDateInvalid || isOtoIdFormInvalid)) ||
      (desktop &&
        (this.detailsForm.invalid ||
          idTypeIsEmpty ||
          areAddressesInvalid ||
          isActivationDateInvalid ||
          isOtoIdFormInvalid))
    );
  }

  public get addresses() {
    return {
      billingAddress: this.checkoutSessionService.billingAddress,
      shippingAddress: this.checkoutSessionService.shippingAddress,
      installationAddress: this.checkoutSessionService.installationAddress,
    };
  }

  public submitButtonLabel(existingCustomer: boolean) {
    if (existingCustomer) {
      if (this.flowService.finishingStep('details')) {
        return this.translateService.getTranslation(['checkout_details', 'save_and_order']);
      } else {
        return this.translateService.getTranslation(['checkout_details', 'save_and_continue']);
      }
    } else {
      if (this.flowService.finishingStep('details')) {
        return this.translateService.getTranslation(['checkout_details', 'submit_button_order_now']);
      } else {
        return this.translateService.getTranslation(['checkout_details', 'next']);
      }
    }
  }

  public fillAddressLookupTranslations() {
    this.addressLookupFields.set({
      location_label: this.translateService.getTranslation(['checkout_details', 'post_code_label']),
      location_placeholder: this.translateService.getTranslation(['checkout_details', 'enter_post_code']),
      location_icon: '',
      street_label: this.translateService.getTranslation(['checkout_details', 'street_label']),
      street_placeholder: this.translateService.getTranslation(['checkout_details', 'enter_street']),
      street_icon: '',
      street_number_label: this.translateService.getTranslation(['checkout_details', 'street_number_label']),
      street_number_placeholder: this.translateService.getTranslation(['checkout_details', 'enter_street_number']),
      street_number_icon: '',
    });
  }

  public initiateForms(): void {
    this.identificationForm
      .get('dateOfBirth')
      .addValidators(
        dateValidator({ futureDatesNotAllowed: true, minDate: this.minDateOfBirth, maxDate: this.maxDateOfBirth })
      );

    this.detailsForm = new FormGroup({
      contactDetails: this.contactDetailsForm,
      identification: this.identificationForm,
    });

    if (!this.flowService.checkoutFlow.detailsFieldsToHide.includes(DetailsFields.ACTIVATION_DATE)) {
      this.wishDateForm = new FormGroup({
        wishDate: new FormControl(this.startCalendarActivationDateAt, [
          Validators.required,
          dateValidator({ minDate: this.minActivationDate, maxDate: this.maxActivationDate }),
        ]),
      });
    }
  }

  public fillForms(): void {
    this.fillContactDetails();
    this.fillWishDate();
  }

  private fillWishDate(): void {
    //check if wishdate from checkout session is valid, if so prefill wishdate field with its value
    const wishdateFromCheckoutSession = parse(this.checkoutSessionService.wishDate, DATE_MS_FORMAT, new Date());
    if (
      wishdateFromCheckoutSession >= this.startCalendarActivationDateAt &&
      wishdateFromCheckoutSession <= this.maxActivationDate
    ) {
      this.wishDate?.setValue(wishdateFromCheckoutSession);
      this.startCalendarActivationDateAt = wishdateFromCheckoutSession;
    }
  }

  private fillContactDetails(): void {
    this.email.setValue(this.checkoutSessionService.contactEmail);
    this.contactPhone.setValue(this.checkoutSessionService.contactPhoneNumber);
  }

  private areAddressesInvalid() {
    if (['MOBILE', 'TV'].includes(this.checkoutSessionService.productSpecFamily) || this.checkoutSessionService.isMBB) {
      return this.billingAddressForm.invalid || this.isShippingAddressFormEnabledAndInvalid();
    } else {
      return this.isShippingAddressFormEnabledAndInvalid() || this.isBillingAddressFormEnabledAndInvalid();
    }
  }

  private isShippingAddressFormEnabledAndInvalid() {
    return (
      !!this.shippingAddressForm.get('enableDifferentShippingAddress').value &&
      (!!this.shippingAddressForm.get('postCode').value ||
        !!this.shippingAddressForm.get('street').value ||
        !!this.shippingAddressForm.get('streetNumber').value ||
        !!this.shippingAddressForm.get('city').value) &&
      this.shippingAddressForm.invalid
    );
  }

  private isBillingAddressFormEnabledAndInvalid() {
    return (
      !!this.billingAddressForm.get('enableDifferentBillingAddress').value &&
      (!!this.billingAddressForm.get('postCode').value ||
        !!this.billingAddressForm.get('street').value ||
        !!this.billingAddressForm.get('streetNumber').value ||
        !!this.billingAddressForm.get('city').value) &&
      this.billingAddressForm.invalid
    );
  }

  public handleInvalidDetailsForms() {
    const mobileView = !this.flowService.desktopView;
    if (mobileView) {
      return this.handleInvalidMobileDetails();
    }
    return this.handleInvalidDesktopDetails();
  }

  public handleUserNeedsToLogin() {
    this.email.setErrors({});
    this.userNeedsToLogin.update(currentValue => {
      return { ...currentValue, clickedNext: true };
    });
  }

  private markDetailsSectionAsTouched() {
    if (this.contactDetailsForm.invalid && this.contactPhone.errors) {
      this.phoneNumberInvalid.set(true);
    }
    this.flowService.markFormTouched(this.contactDetailsForm);
    this.flowService.markFormTouched(this.identificationForm);
    if (!this.isEmailValidForBackend()) {
      this.email.setErrors({ email: true });
    }
    this.scrollToDetailsSection();
  }

  private handleInvalidMobileDetails() {
    const areAddressesInvalid = this.areAddressesInvalid();
    //DETAILS SUBSTEP 0
    if (this.flowService.activeSubstepIndex === 0 && this.detailsForm.invalid) {
      this.markDetailsSectionAsTouched();
    }
    //DETAILS SUBSTEP 1
    if (areAddressesInvalid) {
      this.addressLookupService.addressFormValidations$.next(true);
    }
    if (this.wishDateForm?.invalid) {
      this.flowService.markFormTouched(this.wishDateForm);
    }
    if (this.otoIdForm.invalid) {
      this.flowService.markFormTouched(this.otoIdForm);
    }
    return;
  }

  private handleInvalidDesktopDetails() {
    const areAddressesInvalid = this.areAddressesInvalid();
    if (this.detailsForm.invalid) {
      this.markDetailsSectionAsTouched();
    }

    if (areAddressesInvalid) {
      this.addressLookupService.addressFormValidations$.next(true);
    }
    if (this.wishDateForm?.invalid) {
      this.flowService.markFormTouched(this.wishDateForm);
    }
    if (this.otoIdForm.invalid) {
      this.flowService.markFormTouched(this.otoIdForm);
    }
  }

  scrollToDetailsSection() {
    if (this.contactDetailsForm.invalid) {
      const el = document.getElementById('contact');
      el?.scrollIntoView({ behavior: 'smooth' });
      return;
    } else if (this.identificationForm.invalid) {
      const el = document.getElementById('identification');
      el?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
  }

  public markContactSectionAsInvalid(errors: string[]) {
    if (errors.includes(DetailsFields.EMAIL)) {
      setTimeout(() => {
        this.email.setErrors({ email: true });
      }, 500);
    }
    if (errors.includes(DetailsFields.PHONE)) {
      this.email.setErrors({ phoneNumber: true });
    }
    this.scrollToDetailsSection();
  }

  public areAddressesEqual(
    linecheckAddress: CheckoutService.Address,
    otoIdAddress: LineCheckService.LineCheckResp['installationAddress']
  ) {
    return (
      linecheckAddress.streetNumber === otoIdAddress.streetNumber &&
      linecheckAddress.streetName === otoIdAddress.streetName &&
      linecheckAddress.city === otoIdAddress.city &&
      linecheckAddress.postCode === otoIdAddress.postCode
    );
  }

  public buildUpdateShipmentAddress(existingCustomer: boolean) {
    const data: CheckoutService.UpdateDetailsReq = {
      checkoutSessionId: this.checkoutSessionService.sessionId,
      address: {
        shipmentAddress: {
          postCode: this.finalUserAddress?.postCode,
          streetName: this.finalUserAddress?.streetName,
          streetNumber: this.finalUserAddress?.streetNumber,
          city: this.finalUserAddress?.city,
          ...(this.finalUserAddress?.co ? { co: this.finalUserAddress.co } : {}),
        },
      },
    };

    const sendDiffShippingAddress =
      this.shippingAddressForm.valid &&
      this.shippingAddressForm.get('enableDifferentShippingAddress').value &&
      this.shippingAddressForm.get('postCode').value &&
      this.shippingAddressForm.get('street').value &&
      this.shippingAddressForm.get('streetNumber').value &&
      this.shippingAddressForm.get('city').value;

    if (sendDiffShippingAddress) {
      data.address = {
        ...data.address,
        shipmentAddress: {
          postCode: this.shippingAddressForm.get('postCode').value,
          streetName: this.shippingAddressForm.get('street').value,
          streetNumber: this.shippingAddressForm.get('streetNumber').value,
          city: this.shippingAddressForm.get('city').value,
          ...(this.shippingAddressForm.get('diffPostboxName').value
            ? { co: this.shippingAddressForm.get('diffPostboxName').value }
            : {}),
        },
      };
    }

    return existingCustomer ? this.checkoutBaseService.updateAddressDetails(data) : of(null);
  }

  public billingAddressWasEdited() {
    return (
      this.finalUserAddress.streetNumber !== this.checkoutSessionService?.billingAddress?.streetNumber ||
      this.finalUserAddress.streetName !== this.checkoutSessionService?.billingAddress?.streetName ||
      this.finalUserAddress.city !== this.checkoutSessionService?.billingAddress?.city ||
      this.finalUserAddress.postCode !== this.checkoutSessionService?.billingAddress?.postCode
    );
  }

  public phoneNumberWasEdited() {
    return this.contactPhone.value !== this.checkoutSessionService?.personalDetails?.contactDetail.phoneNumber;
  }

  public isEmailEmpty() {
    return !this.checkoutSessionService?.personalDetails?.contactDetail.email;
  }

  public markDetailSectionAsInvalid(errors: string[]) {
    if (errors.includes(DetailsFields.EMAIL)) {
      this.email.setErrors({ email: true });
    }
    if (errors.includes(DetailsFields.PHONE)) {
      this.contactPhone.setErrors({ phoneNumber: true });
    }
    if (errors.includes(DetailsFields.FIRST_NAME)) {
      this.firstName.setErrors({ text: true });
    }
    if (errors.includes(DetailsFields.LAST_NAME)) {
      this.lastName.setErrors({ text: true });
    }
    if (errors.includes(DetailsFields.DATE_OF_BIRTH)) {
      this.dateOfBirth.setErrors({ notValidDate: true });
    }
    if (errors.includes(`${DetailsFields.BILLING_ADDRESS}.diffPostboxName`)) {
      this.billingAddressForm.get('diffPostboxName').setErrors({ text: true });
    }
    if (errors.includes(`${DetailsFields.SHIPPING_ADDRESS}.diffPostboxName`)) {
      this.shippingAddressForm.get('diffPostboxName').setErrors({ text: true });
    }

    if (errors.includes(DetailsFields.OTO_ID)) {
      this.otoId.setErrors({ fiberLine: true });
    }
    this.scrollToDetailsSection();
  }

  public toggleActivateESIM() {
    this.activateEsim.set(!this.activateEsim());
  }
}
