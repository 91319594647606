<div class="container px-6 mt-6" data-testid="confirmation-page">
  @if (thankyouMessage()) {
    <h2 id="orderGreeting" class="text-center mt-4" data-testid="order-greeting">
      {{ ['checkout_confirmation', 'thank_you_message'] | translate }}, {{ firstName }}!
    </h2>
    <div class="checkout-summary-header flex items-center flex-col m-4">
      <lib-svg
        [svg]="brand.isYallo() ? 'air-drop' : 'air-drop-lebara'"
        class="[&_svg]:md:w-[179px] [&_svg]:md:h-[179px] [&_svg]:size-20"
      ></lib-svg>
    </div>
  }

  @if (orderStatus() !== 'ORDER_COMPLETED') {
    <h3 class="text-center my-4 text-3xl text-[--color-primary]">
      {{ ['checkout_confirmation', 'order_in_progress'] | translate }}
    </h3>
    <p class="text-center my-4">
      {{ ['checkout_confirmation', 'confirmation_message'] | translate }}
    </p>
  }

  <div class="text-center">
    @if (orderStatus() === 'ORDER_COMPLETED') {
      <p class="info-text mb-1.5">
        {{ ['checkout_confirmation', 'order_number'] | translate }}:
        <span class="font-bold info-text">{{ orderNumber() }}</span>
      </p>
    }

    <p class="info-text mb-1.5" data-testid="email-confirmation">
      {{ ['checkout_confirmation', 'confirmation_email'] | translate }}:
      <span class="font-bold break-words info-text">{{ email }}</span>
    </p>

    <p class="info-text">
      {{ ['checkout_confirmation', 'delivery'] | translate }}:
      <span class="font-bold break-words info-text">
        {{
          ['checkout_confirmation', deliveryText]
            | translate
            | replaceToken: { days: checkoutFlow.deliveryDays.toString() }
        }}
      </span>
    </p>

    @if (activationText) {
      <ng-container class="flex flex-wrap justify-center">
        <p class="info-text mt-1.5">
          {{ ['checkout_confirmation', 'activation'] | translate }}:
          <span class="font-bold break-words info-text">
            {{ ['checkout_confirmation', activationText] | translate }}
          </span>
        </p>
      </ng-container>
    }
  </div>

  @if (showInformationCards()) {
    <lib-information-cards [informationCards]="checkoutFlow.informationCards"></lib-information-cards>
  }

  @if (orderStatus() === 'ORDER_COMPLETED' || orderNotFinished()) {
    <div class="flex flex-col items-center py-2 mt-14 md:mt-32 mx-auto max-w-[50rem]">
      <lib-button
        class="mt-5"
        [text]="buttonText()"
        [customButtonClass]="'!min-w-60'"
        (clickEvent)="ctaAction()"
        [disabled]="orderNotFinished() || loadingPrimaryButton()"
        [loading]="orderNotFinished() || loadingPrimaryButton()"
        [type]="'mat-filled-button'"
      ></lib-button>
      <lib-button
        class="mt-3"
        [customButtonClass]="'!min-w-60'"
        svg="fontawesome/file-pdf-o"
        [text]="['pos_confirmation', 'contract_download'] | translate"
        (clickEvent)="downloadContract()"
        [disabled]="orderNotFinished()"
        [type]="'mat-outlined-button'"
      ></lib-button>
    </div>
  }
</div>
