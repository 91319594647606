<lib-toasts></lib-toasts>
<lib-tooltip-global></lib-tooltip-global>
<lib-menu-global></lib-menu-global>
<div [ngClass]="{ hidden: skeletonLoading }">
  <router-outlet></router-outlet>
</div>
@if (skeletonLoading) {
  <lib-checkout-skeleton-loading class="h-screen flex items-center justify-center"></lib-checkout-skeleton-loading>
}

@if (ctaLoading) {
  <div class="fixed w-full h-full top-0 left-0 bg-white opacity-40"></div>
}
