<div>
  <div
    class="mb-8 bg-white rounded border border-slate-300"
    [ngClass]="{
      'shadow-lg bg-gradient-to-r from-[--color-petrol] to-[--color-blue] rounded-lg p-0.5': signatureStarted(),
    }"
  >
    <span class="flex items-end justify-center w-full bg-white text-black rounded-md p-6">
      <lib-svg
        [svg]="'fontawesome/signature'"
        [ngClass]="{ 'fill-[--color-petrol]': signatureStarted() }"
        class="normal-plus mr-1 fill-[--color-gray-2] mb-[-0.313rem]"
      ></lib-svg
      ><canvas [ngClass]="{ 'dotted-spaced-active': signatureStarted() }" class="dotted-spaced"></canvas>
    </span>
  </div>
  <div class="flex flex-col items-center">
    <lib-text-button [text]="['contract_signature', 'clear'] | translate" (clickEvent)="clear()" />
    <ng-content></ng-content>
  </div>
</div>
