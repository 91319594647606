<lib-checkout-details
  [existingCustomer]="isAuthenticated"
  [stepTitle]="
    isAuthenticated
      ? (['checkout_details', 'check_details'] | translate) + ', ' + firstNameLoggedInUser
      : (['checkout_details', 'enter_details'] | translate)
  "
  [userDetailsSubstepTitle]="
    isAuthenticated
      ? (['checkout_details', 'check_personaldetails'] | translate) + ', ' + firstNameLoggedInUser
      : (['checkout_details', 'enter_personal_details'] | translate)
  "
  [userAddressesSubstepTitle]="
    isAuthenticated
      ? (['checkout_details', 'shipping_address'] | translate) + ', ' + firstNameLoggedInUser
      : (['checkout_details', 'billing_address'] | translate)
  "
/>
<div class="flex justify-center md:flex mb-10">
  @if (isAuthenticated) {
    <p class="text-center">
      {{ ['checkout', 'not_you'] | translate }}
      <a class="text-primary font-bold underline cursor-pointer" (click)="checkoutBaseService.logout()">{{
        ['checkout', 'logout'] | translate
      }}</a>
    </p>
  } @else {
    <p class="field-label">
      {{ ['checkout_details', 'existing_customer'] | translate }}
      <a (click)="callOpenLoginModal()" class="text-primary font-bold underline cursor-pointer">{{
        ['login', 'title'] | translate
      }}</a>
    </p>
  }
</div>
