import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';
import { FormFieldComponent, RadioButtonFormFieldComponent } from 'form-field';
import { ModalsService } from 'modal';
import { TranslatePipe, TranslateService } from 'translate';
import { OtoIdModalComponent } from './oto-id-modal/oto-id-modal.component';

@Component({
  selector: 'lib-checkout-fiber',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormFieldComponent, RadioButtonFormFieldComponent, ReactiveFormsModule, TranslatePipe],
  templateUrl: './checkout-fiber.component.html',
})
export class CheckoutFiberComponent {
  private modalsService = inject(ModalsService);
  private translateService = inject(TranslateService);
  @Input({ required: true }) formGroup: FormGroup<{ enterFiberNumberNowOrLater: FormControl; otoId: FormControl }>;
  @Input({ required: true }) autoFocus: boolean;
  @Output() fiberFormBlur = new EventEmitter<{ field: string; control: AbstractControl }>();
  @Output() analyticsEvents = new EventEmitter<{ interaction: string; extraFields?: object }>();
  protected readonly otoIdmaskCustomPatterns: NgxMaskDirective['patterns'] = {
    S: { pattern: new RegExp('[A-B]') },
    A: { pattern: new RegExp('[0-9X]') },
    '0': { pattern: new RegExp('[0-9]') },
  };
  public readonly fiberNumberRadioOptions = [
    { label: ['checkout', 'enter_fiber_number_later'], value: 'later' },
    { label: ['checkout', 'enter_fiber_number_now'], value: 'now' },
  ];

  public get otoId() {
    return this.formGroup.get('otoId');
  }

  public get enterFiberNumberNowOrLater() {
    return this.formGroup.get('enterFiberNumberNowOrLater');
  }

  public otoIdinputTransformFn = (value: unknown): string =>
    typeof value === 'string' ? value.toUpperCase() : String(value);

  public toggleFiberNumberOption(value: string) {
    this.enterFiberNumberNowOrLater.setValue(value);
    if (['later', null].includes(this.enterFiberNumberNowOrLater.value)) {
      this.otoId.disable();
      this.otoId.reset('');
    } else {
      this.otoId.enable();
    }
  }

  public async openOtoIdHelperModal() {
    this.analyticsEvents.emit({ interaction: 'fiber_number_helper' });
    this.modalsService.openDialog(OtoIdModalComponent, {
      data: {
        oto_id_modal_header_image: this.translateService.getPrismicImage([
          'checkout_details',
          'oto_id_modal_header_image',
        ]),
        oto_id_modal_title: this.translateService.getTranslation(['checkout_details', 'oto_id_modal_title']),
        oto_id_modal_content_body: this.translateService.getTranslation([
          'checkout_details',
          'oto_id_modal_content_body',
        ]),
        oto_id_modal_content_footer: this.translateService.getTranslation([
          'checkout_details',
          'oto_id_modal_content_footer',
        ]),
      },
    });
  }

  public onFiberFormBlur(field: 'fiber number', control: AbstractControl) {
    this.fiberFormBlur.emit({ field, control });
  }
}
