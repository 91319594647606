import { CommonModule, NgClass } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  model,
  OnDestroy,
  output,
  signal,
} from '@angular/core';
import SignaturePad, { Options, PointGroup } from 'signature_pad';
import { SvgComponent } from 'icon';
import { TextButtonComponent } from 'sakura-ui';
import { TranslatePipe } from 'translate';

export interface NgSignaturePadOptions extends Options {
  canvasHeight: number;
  canvasWidth: number;
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-signature-pad',
  styleUrls: ['./signature-pad.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgComponent, TranslatePipe, NgClass, TextButtonComponent],
  templateUrl: './signature-pad.component.html',
})
export class SignaturePadComponent implements AfterContentInit, OnDestroy {
  private elementRef = inject(ElementRef);
  public options = model<NgSignaturePadOptions>({ canvasWidth: 630, canvasHeight: 270 });
  private signaturePad: SignaturePad;
  public signatureStarted = signal(false);
  private pointGroup: PointGroup[] = [];
  public hasSignature = output<boolean>();

  @HostListener('window:resize', ['$event']) winResize() {
    if (this.signaturePad) {
      this.pointGroup = this.signaturePad.toData();
    }
    this.updateSignaturePadStyle();
  }

  ngAfterContentInit(): void {
    this.updateSignaturePadStyle();
  }

  ngOnDestroy(): void {
    const canvas: HTMLCanvasElement = this.getCanvas();
    canvas.width = 0;
    canvas.height = 0;
  }

  private updateSignaturePadStyle() {
    if (window.innerWidth > 885) {
      this.options.set({ canvasHeight: 270, canvasWidth: 630 });
    }
    if (window.innerWidth <= 885) {
      this.options.set({ canvasHeight: 270, canvasWidth: 500 });
    }
    if (window.innerWidth <= 600) {
      this.options.set({ canvasHeight: 200, canvasWidth: 300 });
    }
    if (window.innerWidth <= 400) {
      this.options.set({ canvasHeight: 200, canvasWidth: 230 });
    }
    this.initiateSignaturePadCanva();
  }

  private initiateSignaturePadCanva() {
    const canvas: HTMLCanvasElement = this.getCanvas();
    if (this.options().canvasHeight) {
      canvas.height = this.options().canvasHeight;
    }

    if (this.options().canvasWidth) {
      canvas.width = this.options().canvasWidth;
    }

    this.signaturePad = new SignaturePad(canvas, this.options());
    this.signaturePad.addEventListener('beginStroke', () => {
      this.signatureStarted.set(true);
    });

    this.signaturePad.addEventListener('endStroke', () => {
      this.hasSignature.emit(true);
    });

    this.signaturePad.fromData(this.pointGroup);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signatureStarted.set(false);
    this.hasSignature.emit(false);
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  public getSignature(imageType: string = 'image/png', quality: number = 1): string {
    return !this.isEmpty() && this.signaturePad.toDataURL(imageType, quality).split(',')[1];
  }

  private getCanvas(): HTMLCanvasElement {
    return this.elementRef.nativeElement.querySelector('canvas');
  }
}
