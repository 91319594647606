import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'lib-tab',
  templateUrl: './tab.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @ViewChild('content') public contentTemplate: TemplateRef<unknown>;
  @ViewChild('header') public headerTemplate: TemplateRef<unknown>;
}
