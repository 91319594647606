import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CheckoutService as MsCheckoutService } from '@yol-digital/ms-client';
import { CheckoutSessionService } from 'checkout-session';
import {
  AddressLookupFormFieldComponent,
  FormFieldComponent,
  ToggleButtonComponent,
  toggledContentAnimation,
} from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { AddressLineCheckPrefill, AddressLookupFields } from 'utils';
import Address = MsCheckoutService.Address;
@Component({
  selector: 'lib-checkout-address',
  standalone: true,
  templateUrl: './checkout-address.component.html',
  animations: [toggledContentAnimation],
  imports: [
    CommonModule,
    AddressLookupFormFieldComponent,
    FormFieldComponent,
    ReactiveFormsModule,
    ToggleButtonComponent,
    TranslatePipe,
    SvgComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutAddressComponent implements OnInit {
  checkoutSessionService = inject(CheckoutSessionService);
  @Input() formGroup: FormGroup<{ shippingAddress: FormGroup; billingAddress: FormGroup }>;
  @Input() productInformation: { productClass: string; productFamily: string };
  @Input() isMBB: boolean;
  @Input() addresses: {
    billingAddress: Address;
    shippingAddress: Address;
    installationAddress: Address;
  };
  @Input() addressLookupFields?: AddressLookupFields;
  @Input() showFields = { billingAddress: true, shippingAddress: true };
  @Input() existingCustomer: boolean;
  @Input() title: string | undefined;
  @Input() showBillingToggle = true;
  @Output() addressFormBlur = new EventEmitter<{ field: string; control: AbstractControl }>();
  isEditableField = false;
  shippingAddressPrefill?: AddressLineCheckPrefill;
  billingAddressPrefill?: AddressLineCheckPrefill;
  addressLookupGridClasses =
    'lg:grid lg:grid-cols-8 lg:gap-x-6 [&>:nth-child(1)]:lg:col-span-4 [&>:nth-child(2)]:lg:col-span-2 [&>:nth-child(3)]:lg:col-span-2 [&>:nth-child(2)]:2xl:col-span-3 [&>:nth-child(3)]:2xl:col-span-1';

  public get installationAddress() {
    return this.addresses?.installationAddress;
  }

  public get shippingAddressForm() {
    return this.formGroup.get('shippingAddress') as FormGroup;
  }

  public get billingAddressForm() {
    return this.formGroup.get('billingAddress') as FormGroup;
  }

  get enableShipping() {
    return this.shippingAddressForm.get('enableDifferentShippingAddress');
  }

  get enableBilling() {
    return this.billingAddressForm.get('enableDifferentBillingAddress');
  }

  private get shippingAddressObj() {
    return {
      postCode: this.shippingAddressForm.get('postCode'),
      city: this.shippingAddressForm.get('city'),
      street: this.shippingAddressForm.get('street'),
      streetNumber: this.shippingAddressForm.get('streetNumber'),
      diffPostboxName: this.shippingAddressForm.get('diffPostboxName'),
    };
  }

  private get billingAddressObj() {
    return {
      postCode: this.billingAddressForm.get('postCode'),
      city: this.billingAddressForm.get('city'),
      street: this.billingAddressForm.get('street'),
      streetNumber: this.billingAddressForm.get('streetNumber'),
      diffPostboxName: this.billingAddressForm.get('diffPostboxName'),
    };
  }

  public get productClass() {
    return this.productInformation.productClass;
  }

  public get productFamily() {
    return this.productInformation.productFamily;
  }

  ngOnInit() {
    this.fillAddresses();
    if (this.isAuthenticated && this.isExistingAddress()) {
      this.isEditableField = true;
    }
  }

  private isExistingAddress() {
    if (
      this.showFields.billingAddress &&
      (!this.billingAddressObj.city.value ||
        !this.billingAddressObj.postCode.value ||
        !this.billingAddressObj.street.value ||
        !this.billingAddressObj.streetNumber.value) &&
      !this.addresses.billingAddress
    )
      return true;
  }

  get isAuthenticated() {
    return this.checkoutSessionService.isAuthenticated;
  }

  private fillAddresses() {
    if (this.addresses) {
      const { shippingAddress } = this.addresses;

      // Fill billing address from selfcare if authenticated
      this.billingAddressPrefill = {
        postCode: this.checkoutSessionService?.billingAddress?.postCode,
        city: this.checkoutSessionService?.billingAddress?.city,
        streetName: this.checkoutSessionService?.billingAddress?.streetName,
        streetNumber: this.checkoutSessionService?.billingAddress?.streetNumber,
      };

      this.billingAddressObj.postCode.setValue(this.checkoutSessionService?.billingAddress?.postCode);
      this.billingAddressObj.city.setValue(this.checkoutSessionService?.billingAddress?.city);
      this.billingAddressObj.street.setValue(this.checkoutSessionService?.billingAddress?.streetName);
      this.billingAddressObj.streetNumber.setValue(this.checkoutSessionService?.billingAddress?.streetNumber);
      this.billingAddressObj.diffPostboxName?.setValue(this.checkoutSessionService?.billingAddress?.co);

      // fill address if different
      if (this.diffAddresses('shipping')) {
        if (shippingAddress) {
          this.toggleDifferentAddresses('shipping');
          this.shippingAddressPrefill = {
            postCode: shippingAddress.postCode,
            city: shippingAddress.city,
            streetName: shippingAddress.streetName,
            streetNumber: shippingAddress.streetNumber,
          };

          this.shippingAddressObj.postCode.setValue(shippingAddress.postCode);
          this.shippingAddressObj.city.setValue(shippingAddress.city);
          this.shippingAddressObj.street.setValue(shippingAddress.streetName);
          this.shippingAddressObj.streetNumber.setValue(shippingAddress.streetNumber);
          this.shippingAddressObj.diffPostboxName?.setValue(shippingAddress.co);
        }
      }

      if (this.diffAddresses('billing') || ['MOBILE', 'TV'].includes(this.productFamily) || this.isMBB) {
        this.toggleDifferentAddresses('billing');
        this.billingAddressPrefill = {
          postCode: this.checkoutSessionService?.billingAddress?.postCode,
          city: this.checkoutSessionService?.billingAddress?.city,
          streetName: this.checkoutSessionService?.billingAddress?.streetName,
          streetNumber: this.checkoutSessionService?.billingAddress?.streetNumber,
        };

        this.billingAddressObj.postCode.setValue(this.checkoutSessionService?.billingAddress?.postCode);
        this.billingAddressObj.city.setValue(this.checkoutSessionService?.billingAddress?.city);
        this.billingAddressObj.street.setValue(this.checkoutSessionService?.billingAddress?.streetName);
        this.billingAddressObj.streetNumber.setValue(this.checkoutSessionService?.billingAddress?.streetNumber);
        this.billingAddressObj.diffPostboxName?.setValue(this.checkoutSessionService?.billingAddress?.co);
      }
    }
  }

  editField() {
    this.isEditableField = !this.isEditableField;
  }

  toggleDifferentAddresses(type: 'shipping' | 'billing') {
    if (type === 'shipping') {
      this.enableShipping.setValue(!this.enableShipping.value);
      if (!this.enableShipping.value) {
        this.shippingAddressForm.reset();
      }
    } else {
      this.enableBilling.setValue(!this.enableBilling.value);
      if (!this.enableBilling.value) {
        this.billingAddressForm.reset();
      }
    }
  }

  private diffAddresses(type: 'shipping' | 'billing'): boolean {
    const { billingAddress, shippingAddress, installationAddress } = this.addresses;
    const addressToCompare =
      this.productClass === 'HFC' || this.productClass === 'FIBER' ? installationAddress : billingAddress;

    return (
      (type === 'shipping' &&
        shippingAddress &&
        (shippingAddress.city !== addressToCompare.city ||
          shippingAddress.postCode !== addressToCompare.postCode ||
          shippingAddress.streetName !== addressToCompare.streetName ||
          shippingAddress.streetNumber !== addressToCompare.streetNumber)) ||
      (type === 'billing' &&
        billingAddress &&
        (billingAddress.city !== addressToCompare.city ||
          billingAddress.postCode !== addressToCompare.postCode ||
          billingAddress.streetName !== addressToCompare.streetName ||
          billingAddress.streetNumber !== addressToCompare.streetNumber))
    );
  }

  setShippingAddressFormFields(value: string, formField: string) {
    this.shippingAddressForm.controls[formField].setValue(value);
    this.shippingAddressForm.markAsDirty();
  }

  setBillingAddressFormFields(value: string, formField: string) {
    this.billingAddressForm.controls[formField].setValue(value);
    this.billingAddressForm.markAsDirty();
  }

  onAddressBlur(addressType: string, event: { field: string; control: AbstractControl }) {
    this.addressFormBlur.emit({ field: addressType + ' - ' + event.field, control: event.control });
  }
}
