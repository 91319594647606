<form [formGroup]="formGroup">
  <div class="lg:grid lg:grid-cols-2 lg:gap-x-6">
    @if (showFields.email) {
      <div class="relative">
        @if (!existingCustomer() || !existingContactDetails().email) {
          <lib-input-form-field
            [loading]="validateEmail"
            [isBackendValid]="isBackendValid() && !isEditing"
            class="w-full"
            label="{{ ['checkout_details', 'enter_email'] | translate }}"
            type="email"
            formControlName="email"
            placeholder="{{ ['checkout_details', 'enter_email'] | translate }}"
            (onBlur)="onContactEmailBlur(emailControl)"
            (onInput)="onInput()"
            data-testid="email"
            [autoFocus]="existingCustomer()"
          >
          </lib-input-form-field>
        } @else {
          <div
            class="lg:mr-32 flex flex-row lg:block justify-between lg:mb-2"
            [ngClass]="{
              'mb-8': !existingContactDetails().contactPhone,
              'mb-3': existingContactDetails().contactPhone,
            }"
          >
            <div data-testid="email-existing-user">
              <label class="text-[var(--color-gray-1)] text-base">
                <ngx-dynamic-hooks
                  [content]="['checkout_details', 'email_label'] | translate"
                  [options]="{ sanitize: false }"
                ></ngx-dynamic-hooks>
              </label>
              <p class="text-gray-2">{{ existingContactDetails().email }}</p>
            </div>
          </div>
        }

        @if (userNeedsToLogin?.value) {
          <p
            class="relative -top-5 px-5 md:-top-7 break-word [&_p]:!text-xs [&_p]:md:!text-sm font-normal cursor-pointer"
            [ngClass]="{ 'text-[--mat-form-field-error-text-color]': userNeedsToLogin?.clickedNext }"
          >
            <ngx-dynamic-hooks
              [content]="[detailsPage, 'user_needs_to_login_text'] | translate"
              [options]="{ sanitize: false }"
              (click)="manageRecognizedClient()"
            ></ngx-dynamic-hooks>
          </p>
        }
      </div>
    }

    @if (showFields.contactPhone && showEditablePhoneField()) {
      <lib-input-form-field
        class="w-full"
        label="{{ ['checkout_details', 'contact_phone_label'] | translate }}"
        type="text"
        formControlName="contactPhone"
        placeholder="{{ ['checkout_details', 'enter_contact_number'] | translate }}"
        (onBlur)="onContactPhoneBlur(phoneControl)"
      ></lib-input-form-field>
    } @else {
      <div class="mb-8">
        <label class="block text-[var(--color-gray-1)] text-base">{{
          ['checkout_details', 'contact_phone_label'] | translate
        }}</label>
        <div class="flex items-center justify-between mt-1">
          <p>{{ existingContactDetails().contactPhone }}</p>
          <div
            class="flex items-center ml-2 cursor-pointer order-last"
            (click)="toggleEditField()"
            data-testid="edit-checkout"
          >
            <lib-svg
              svg="fontawesome/edit"
              class="cursor-pointer ml-1 h-3 w-3 sm:w-4 sm:h-4 [&_svg]:w-full [&_svg]:h-auto fill-[--color-gray-2] block box-content"
              (click)="toggleEditField()"
            ></lib-svg>
            <span class="font-bold text-sm sm:text-base mx-1 text-[#707070]">{{
              ['checkout', 'edit'] | translate
            }}</span>
          </div>
        </div>
      </div>
    }
  </div>
</form>
