<lib-modal class="p-7 block max-h-screen">
  @if (modalData.oto_id_modal_header_image) {
    <figure class="flex justify-center" modal-header>
      <img
        [src]="modalData.oto_id_modal_header_image.url"
        [alt]="modalData.oto_id_modal_header_image.alt"
        class="min-h-[25vmin] max-h-[25vmin]"
      />
    </figure>
  }

  <div class="md:w-[720px]" modal-body>
    <div
      class="mb-4"
      [innerHTML]="modalData.oto_id_modal_title || (['checkout_details', 'oto_id_modal_title'] | translate)"
    ></div>
    <div
      class="[&_p]:mb-4"
      [innerHTML]="
        modalData.oto_id_modal_content_body || (['checkout_details', 'oto_id_modal_content_body'] | translate)
      "
    ></div>
  </div>

  <div class="flex flex-col items-center mt-12 pb-4 md:pb-0" modal-footer>
    <div
      class="modal-footer"
      [innerHTML]="
        modalData.oto_id_modal_content_footer || (['checkout_details', 'oto_id_modal_content_footer'] | translate)
      "
    ></div>

    <a
      href="tel:0800004854"
      class="text-[--color-primary] flex items-center justify-center leading-none mt-2 cursor-pointer font-extrabold"
    >
      <lib-svg class="fill-[--color-primary] mr-2" svg="fontawesome/phone"></lib-svg>
      0800 00 48 54
    </a>
  </div>
</lib-modal>
