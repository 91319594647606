@defer (when product()) {
  @if (product()) {
    <div
      class="relative product sales-box text-center h-full flex flex-col border border-solid border-[--color-gray-3]"
      data-testid="product-box"
    >
      <div class="h-8">
        @if (promotion() && hasDiscountedCost()) {
          <lib-promotion-banner [text]="promotionBannerText()" [dynamicSize]="false"> </lib-promotion-banner>
        }
      </div>
      <div class="flex flex-col px-3 sm:px-5 h-full pt-2 justify-between">
        <ng-container *ngTemplateOutlet="productBodyTemplate"></ng-container>
      </div>
    </div>
  }
} @placeholder {
  <div class="p-4 sales-box h-full flex flex-col border border-solid border-[--color-gray-3]">
    <ngx-skeleton-loader class="mx-auto w-1/2 [&_.skeleton-loader]:!h-10 my-2" count="1" />
    <ngx-skeleton-loader
      class="mx-auto [&_.skeleton-loader]:!h-20 [&_.skeleton-loader]:!w-56 [&_.skeleton-loader]:md:!w-80"
      count="1"
    />
    <ngx-skeleton-loader class="grid grid-cols-2 gap-6 [&_.skeleton-loader]:!h-10" count="2" />
    <ngx-skeleton-loader class="grid grid-cols-2 gap-6 [&_.skeleton-loader]:!h-10" count="2" />
    <ngx-skeleton-loader class="mx-auto my-2 w-1/2 [&_.skeleton-loader]:!h-10" count="1" />
    <ngx-skeleton-loader class="mx-auto w-1/2 [&_.skeleton-loader]:!h-5" count="1" />
    <ngx-skeleton-loader class="mx-auto [&_.skeleton-loader]:!mx-auto w-1/3 [&_.skeleton-loader]:!h-3" count="1" />
  </div>
}

<ng-template #productBodyTemplate>
  <div class="flex flex-col justify-center">
    <p
      class="text-center lg:text-4xl text-2xl font-black"
      [ngClass]="{
        'bg-gradient-to-r from-[--color-primary-lightened] to-[--color-blue-darkened] to-80% text-transparent bg-clip-text':
          isYallo,
        'text-[--color-blue-light]': !isYallo,
      }"
    >
      {{ product().name }}
    </p>
  </div>

  @if (product().prismicTable?.type === 'table_mobile') {
    <lib-table-mobile class="mb-2 [&_td:first-child]:w-24" [product]="product()"></lib-table-mobile>
  }

  @if (product().prismicTable?.type === 'table_home_internet') {
    <lib-table-home-internet class="mb-2 [&_td:first-child]:w-28" [product]="product()"></lib-table-home-internet>
  }

  @if (product().prismicTable?.type === 'table_data_products') {
    <lib-table-data-products class="mb-2 [&_td:first-child]:w-28" [product]="product()"></lib-table-data-products>
  }

  @if (product().prismicTable?.type === 'table_tv') {
    <lib-table-tv class="mb-2 [&_td:first-child]:w-28" [product]="product()"></lib-table-tv>
  }

  <table class="w-full [&_td]:p-2 [&_td]:text-xs">
    <tr>
      <td class="font-bold flex flex-col items-center">
        <lib-svg class="fill-[--color-gray]" svg="fontawesome/file-lines-regular"></lib-svg>
        {{ ['checkout', 'contract_duration'] | translate }}
      </td>
      <td>
        {{ contractDurationText() }}
      </td>
    </tr>
    <tr>
      <td class="font-bold flex flex-col items-center">
        <lib-svg class="fill-[--color-gray]" svg="cost"></lib-svg>
        {{ ['checkout', 'one_time_cost'] | translate }}
      </td>
      <td>{{ oneTimeCost() | currencyFormatter: false }} {{ ['checkout_confirmation', 'activation'] | translate }}</td>
    </tr>
  </table>

  @if (showPromoCodeInput) {
    <div class="md:xl-h:mt-4 mt-4">
      @if (!!checkoutSessionService.newPromoCodeApplied()) {
        <div class="flex flex-col items-center">
          <small>{{ ['checkout', 'promo_code_label'] | translate }}</small>
          <div class="flex items-center" data-testid="check-icon">
            <p>{{ checkoutSessionService.newPromoCodeApplied() }}</p>
            <lib-svg class="fill-[--color-primary]" svg="fontawesome/check"></lib-svg>
          </div>
        </div>
      } @else {
        <form [formGroup]="promoCodeForm">
          <lib-input-form-field
            [isBackendValid]="false"
            class="w-full md:xl-h:!mb-0"
            label="{{ ['checkout', 'promo_code_label'] | translate }}"
            type="text"
            formControlName="promoCode"
            [placeholder]="['checkout', 'promo_code_placeholder'] | translate"
            [clearInputSuffix]="'invalid'"
            [actionSuffix]="true"
            (actionButtonClick)="addNewPromoCode()"
            data-testid="promo-code"
          >
          </lib-input-form-field>
        </form>
      }
    </div>
  }

  <lib-product-box-price
    [productItem]="productItem()"
    [hasDiscountedCost]="hasDiscountedCost()"
    [discountedPrice]="discountedPrice()"
    [loadingPrice]="loadingPrice()"
  ></lib-product-box-price>
</ng-template>
