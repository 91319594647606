import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalComponent } from 'modal';
import { CheckoutBasketComponent } from '../checkout-basket/checkout-basket.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-checkout-basket-modal',
  standalone: true,
  imports: [ModalComponent, CheckoutBasketComponent],
  templateUrl: './checkout-basket-modal.component.html',
})
export class CheckoutBasketModalComponent {}
