import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Routes } from '@angular/router';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { featureFlagResolver } from 'feature-flag';
import { BaseLangGuard, langUrlMatcher } from 'language';
import { TitleResolver } from 'title';
import { App } from 'utils';
import { BaseComponent } from './pages/base/base.component';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { DetailsComponent } from './pages/details/details.component';
import { DummyComponent } from './pages/dummy/dummy.component';
import { PhoneNumberComponent } from './pages/phone-number/phone-number.component';
import { SignContractComponent } from './pages/sign-contract/sign-contract.component';
import { CheckoutGuard } from './services/checkout.guard';

const stepRoutes: Route[] = [
  {
    path: 'phone-number',
    component: PhoneNumberComponent,
    title: TitleResolver,
    canActivate: [(route: ActivatedRouteSnapshot) => inject(CheckoutGuard).phoneNumberGuard(route.params.sessionId)],
    resolve: {
      session: (route: ActivatedRouteSnapshot) =>
        inject(CheckoutBaseService).loadSession(route.params.sessionId, route.routeConfig.path),
    },
  },
  {
    path: 'details',
    component: DetailsComponent,
    title: TitleResolver,
    canActivate: [(route: ActivatedRouteSnapshot) => inject(CheckoutGuard).detailsGuard(route.params.sessionId)],
    resolve: {
      session: (route: ActivatedRouteSnapshot) =>
        inject(CheckoutBaseService).loadSession(route.params.sessionId, route.routeConfig.path),
    },
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    title: TitleResolver,
    canActivate: [(route: ActivatedRouteSnapshot) => inject(CheckoutGuard).confirmationGuard(route.params.sessionId)],
    resolve: {
      session: (route: ActivatedRouteSnapshot) =>
        inject(CheckoutBaseService).loadSession(route.params.sessionId, route.routeConfig.path),
    },
  },
  {
    path: 'sign-contract',
    component: SignContractComponent,
    title: TitleResolver,
    resolve: {
      session: (route: ActivatedRouteSnapshot) =>
        inject(CheckoutBaseService).loadSession(route.params.sessionId, route.routeConfig.path, false),
      contracts: () => inject(CheckoutBaseService).generateAndGetContracts(true),
      orderStatus: () => inject(CheckoutDataAccessService).retrieveOrderStatus(),
    },
  },
  {
    path: '**',
    component: DummyComponent,
    canActivate: [() => inject(CheckoutGuard).wrongRouteGuard()],
  },
];

const routes: Route[] = [
  {
    path: 'start/:productCode',
    component: DummyComponent,
    canActivate: [
      (route: ActivatedRouteSnapshot) =>
        inject(CheckoutBaseService).startSession(route.params.productCode, route.queryParams),
    ],
  },
  {
    path: ':sessionId',
    component: BaseComponent,
    children: stepRoutes,
  },
];

export const appRoutes: Routes = [
  {
    matcher: langUrlMatcher,
    component: DummyComponent,
    children: routes,
    resolve: {
      featureFlags: featureFlagResolver,
      app: () => App.checkout,
    },
  },
  {
    path: '**',
    component: DummyComponent,
    canActivate: [BaseLangGuard],
  },
];
