import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import {
  CheckoutService as MsCheckoutService,
  LineCheckService as MsLineCheckService,
  SelfcareService,
} from '@yol-digital/ms-client';
import { isEqual, isMatch, parse } from 'date-fns';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { catchError, EMPTY, finalize, forkJoin, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { AnalyticsService } from 'analytics';
import { CheckoutAddressComponent } from 'checkout-address';
import { CheckoutBaseService } from 'checkout-base';
import { CheckoutContactDetailsComponent } from 'checkout-contact-details';
import { CheckoutDataAccessService } from 'checkout-data-access';
import { CheckoutFiberComponent } from 'checkout-fiber';
import { CheckoutFlowService } from 'checkout-flow';
import { CheckoutIdentificationComponent } from 'checkout-identification';
import { CheckoutLoadingService } from 'checkout-loading';
import { CheckoutSessionService } from 'checkout-session';
import { CheckoutStepComponent, CheckoutSubstepComponent } from 'checkout-step';
import { CHECKOUT_ERRORS, DetailsFields } from 'checkout-utils';
import { DatePickerFormFieldComponent, ToggleButtonComponent } from 'form-field';
import { LanguageService } from 'language';
import { ToastService } from 'toast';
import { TranslatePipe } from 'translate';
import {
  BrowserService,
  DATE_FORMAT,
  DATE_MS_FORMAT,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  formatDateToMsFormat,
  transformPhoneNumber,
} from 'utils';
import { CheckoutDetailsService } from '../checkout-details.service';
import { BackupPromotionToastComponent } from '../components/backup-promotion-toast/backup-promotion-toast.component';

@Component({
  selector: 'lib-checkout-details',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CheckoutStepComponent,
    CheckoutSubstepComponent,
    ReactiveFormsModule,
    DatePickerFormFieldComponent,
    TranslatePipe,
    CheckoutAddressComponent,
    CheckoutContactDetailsComponent,
    CheckoutIdentificationComponent,
    CheckoutFiberComponent,
    ToggleButtonComponent,
    DynamicHooksComponent,
  ],
})
export class CheckoutDetailsComponent implements OnInit, AfterContentChecked, OnDestroy {
  protected checkoutDetailsService = inject(CheckoutDetailsService);
  private languageService = inject(LanguageService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  public checkoutBaseService = inject(CheckoutBaseService);
  private checkoutDataAccessService = inject(CheckoutDataAccessService);
  public checkoutSessionService = inject(CheckoutSessionService);
  public flowService = inject(CheckoutFlowService);
  private loadingService = inject(CheckoutLoadingService);
  private toastService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);
  private lang = inject(LanguageService);
  private browserService = inject(BrowserService);
  private analyticsService = inject(AnalyticsService);
  public stepPath = 'details';
  public existingCustomer = input<boolean>();
  public stepTitle = input<string>('');
  public userDetailsSubstepTitle = input<string>('');
  public userAddressesSubstepTitle = input<string>('');
  public headerTitle = input<string>();
  public headerSubTitle = input<string>();
  private shippingAddressForm = this.checkoutDetailsService.shippingAddressForm;
  private billingAddressForm = this.checkoutDetailsService.billingAddressForm;

  get finalUserAddress() {
    return this.checkoutDetailsService.finalUserAddress;
  }

  ngAfterContentChecked() {
    this.checkoutDetailsService.fillAddressLookupTranslations();
  }

  ngOnInit() {
    this.checkoutDetailsService.initiateForms();
    this.checkoutDetailsService.fillForms();

    this.flowService.loginModalClosed$.subscribe(() => {
      if (this.existingCustomer()) {
        this.browserService.reload();
      }
    });
    if (this.checkoutBaseService.backupPromotionApplied()) {
      const monthly_cost = this.checkoutSessionService.monthlyFee;
      const discount = this.checkoutSessionService.discount;

      const newPrice = discount ? monthly_cost - discount : monthly_cost;

      const component = {
        comp: BackupPromotionToastComponent,
        inputs: {
          name: this.checkoutSessionService.account?.ownerDetails?.firstName,
          oldPrice: monthly_cost,
          newPrice: newPrice,
        },
      };
      this.toastService.add(null, false, 3000, component);
    }

    this.checkoutBaseService.sendScanIdSuccessAnalytics();
  }

  private addCustomerDetails() {
    const transformedcontactPhone = transformPhoneNumber(this.checkoutDetailsService.contactPhone.value);
    const personalDetails: MsCheckoutService.UpdateCustomer = {
      preferredLang: this.lang.current,
      title: this.checkoutDetailsService.title.value,
      contactDetail: { phoneNumber: transformedcontactPhone },
      firstName: this.checkoutDetailsService.firstName.value,
      lastName: this.checkoutDetailsService.lastName.value,
      birthday: formatDateToMsFormat(this.checkoutDetailsService.dateOfBirth),
      nationality: this.checkoutDetailsService.nationality?.value,
    };
    const data: Omit<MsCheckoutService.UpdateCustomerDetailsReq, 'checkoutSessionId'> = {
      personalDetails,
    };

    if (this.finalUserAddress?.postCode) {
      data.address = this.checkoutDetailsService.finalUserObject;
    }

    const sendDiffShippingAddress =
      this.shippingAddressForm.valid &&
      this.shippingAddressForm.get('enableDifferentShippingAddress').value &&
      this.shippingAddressForm.get('postCode').value &&
      this.shippingAddressForm.get('street').value &&
      this.shippingAddressForm.get('streetNumber').value &&
      this.shippingAddressForm.get('city').value;

    if (sendDiffShippingAddress) {
      data.address = {
        ...data.address,
        shipmentAddress: {
          postCode: this.shippingAddressForm.get('postCode').value,
          streetName: this.shippingAddressForm.get('street').value,
          streetNumber: this.shippingAddressForm.get('streetNumber').value,
          city: this.shippingAddressForm.get('city').value,
          ...(this.shippingAddressForm.get('diffPostboxName').value
            ? { co: this.shippingAddressForm.get('diffPostboxName').value }
            : {}),
        },
      };
    }

    const sendDiffBillingAddress =
      this.billingAddressForm.valid &&
      this.billingAddressForm.get('enableDifferentBillingAddress').value &&
      this.billingAddressForm.get('postCode').value &&
      this.billingAddressForm.get('street').value &&
      this.billingAddressForm.get('streetNumber').value &&
      this.billingAddressForm.get('city').value;

    if (sendDiffBillingAddress) {
      data.address = {
        ...data.address,
        billingAddress: {
          postCode: this.billingAddressForm.get('postCode').value,
          streetName: this.billingAddressForm.get('street').value,
          streetNumber: this.billingAddressForm.get('streetNumber').value,
          city: this.billingAddressForm.get('city').value,
          ...(this.billingAddressForm.get('diffPostboxName').value
            ? { co: this.billingAddressForm.get('diffPostboxName').value }
            : {}),
        },
      };
    }
    return this.checkoutBaseService.addCustomerDetails(data).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error.error === CHECKOUT_ERRORS.ERR_JSON_VALIDATION) {
          const formValidationErrors = this.checkoutBaseService.handleJsonValidationError(err.error.fields);
          this.checkoutDetailsService.markDetailSectionAsInvalid(formValidationErrors);
        }
        return EMPTY;
      })
    );
  }

  private confirmPersonalId() {
    if (!this.checkoutBaseService.shouldDoIdCheck()) return of(null);
    const nationality = this.checkoutDetailsService.nationality.value;
    const typeId = this.checkoutDetailsService.idType.value;
    // The id number and expiry date are not there when the data was entered manually
    const number = this.checkoutDetailsService.idNumber?.value || undefined;
    const expireDate = this.checkoutDetailsService.idExpiryDate?.value
      ? formatDateToMsFormat(this.checkoutDetailsService.idExpiryDate)
      : undefined;

    return this.checkoutDataAccessService.confirmPersonalIdDetails({
      nationality,
      expireDate,
      typeId,
      number,
    });
  }

  private updateExistingCustomerDetails() {
    let updateBillingAddressCall$: Observable<void | { error: string } | null> = of(null);
    let updateContactPhone$: Observable<void | { error: string } | null> = of(null);
    let updateContactEmail$: Observable<{ refId?: string } | { error: string }> = of(null);
    if (this.finalUserAddress && this.checkoutDetailsService.billingAddressWasEdited()) {
      const addressData: SelfcareService.UpdateBillingAddressReq = {
        streetName: this.finalUserAddress.streetName,
        streetNumber: this.finalUserAddress.streetNumber,
        city: this.finalUserAddress.city,
        postCode: this.finalUserAddress.postCode,
      };

      updateBillingAddressCall$ = this.checkoutBaseService.updateUserBillingAddress(addressData);
    }
    if (this.checkoutDetailsService.phoneNumberWasEdited()) {
      const transformedcontactPhone = transformPhoneNumber(this.checkoutDetailsService.contactPhone.value);

      updateContactPhone$ = this.checkoutBaseService.updateContactDetails({ contactNumber: transformedcontactPhone });
    }
    if (this.checkoutDetailsService.isEmailEmpty()) {
      updateContactEmail$ = this.checkoutBaseService.updateUserEmail(this.checkoutDetailsService.email.value);
    }
    return forkJoin([updateBillingAddressCall$, updateContactPhone$, updateContactEmail$]);
  }

  onNextClick(finishOrder = false) {
    if (this.checkoutDetailsService.email.disabled) return;
    if (this.checkoutDetailsService.userNeedsToLogin().value) {
      return this.checkoutDetailsService.handleUserNeedsToLogin();
    }
    if (!this.checkoutDetailsService.isEmailValidForBackend()) return;
    if (
      this.checkoutDetailsService.invalidDetailsForms ||
      (!this.checkoutDetailsService.fieldsToHide.includes(DetailsFields.SCAN_ID_CTA) &&
        !this.checkoutDetailsService.scanIdNowOrLater.value)
    ) {
      this.checkoutDetailsService.scanIdNowOrLater.setValue('later');
      return this.checkoutDetailsService.handleInvalidDetailsForms();
    }

    if (this.existingCustomer()) {
      return this.checkoutBaseService.checkForUserSessionExpired().subscribe(expired => {
        if (!expired) {
          return this.handleNextClickOnDetails(finishOrder);
        }
        //Handle the expired session case : redirect to login page
        const redirectUrl = this.browserService.getOrigin();
        return this.browserService.redirect(
          `${this.config.websiteUrl}/${this.languageService.current}/auth/login?redirectPath=${redirectUrl}/${this.languageService.current}/${this.checkoutSessionService.sessionId}/details`
        );
      });
    }
    return this.handleNextClickOnDetails(finishOrder);
  }

  handleNextClickOnDetails(finishOrder = false): void {
    const isMobileFirstStep = this.flowService.activeSubstepIndex === 0 && !this.flowService.desktopView;
    this.checkoutDetailsService.otoIdFieldFocus.set(false);
    if (finishOrder) this.loadingService.showProgressBarLoading();
    else this.loadingService.showCTALoading();

    // Update Wish Date
    const wishDateFromCheckoutSession = parse(this.checkoutSessionService.wishDate, DATE_MS_FORMAT, new Date());
    const inputWishDate = isMatch(this.checkoutDetailsService.wishDate?.value, DATE_FORMAT)
      ? parse(this.checkoutDetailsService.wishDate?.value, DATE_FORMAT, new Date())
      : this.checkoutDetailsService.wishDate?.value;
    const shouldUpdateWishDate =
      this.checkoutDetailsService.wishDate?.valid && !isEqual(wishDateFromCheckoutSession, inputWishDate);
    const updateWishdateCall$ = shouldUpdateWishDate ? this.callUpdateWishDate() : of(null);

    // Update OTO ID
    const shouldUpdateOtoId =
      !!this.checkoutDetailsService.otoId.value.length && this.checkoutDetailsService.otoId.valid;
    const lineCheckByOtoIdCall$ = shouldUpdateOtoId
      ? this.checkoutDataAccessService.lineCheckByOtoId(this.checkoutDetailsService.otoId.value)
      : of(null);

    // Update Details
    const updateDetailsCall$ = this.existingCustomer()
      ? this.updateExistingCustomerDetails()
      : this.addCustomerDetails();

    // call addaccount
    const addAccountIdCall$ = this.existingCustomer() ? this.checkoutBaseService.addAccountIdV1() : of(null);
    // Confirm Personal ID

    const confirmPersonalId$ = this.checkoutDetailsService.fieldsToHide?.includes(DetailsFields.SCAN_ID_CTA)
      ? of(false)
      : this.confirmPersonalId();

    const confirmPersonalDetails$ = this.existingCustomer()
      ? of(false)
      : this.checkoutDataAccessService.confirmCustomerDetails().pipe(
          catchError(err => {
            this.loadingService.hideLoading();
            if (!err?.wasCaught) {
              this.checkoutBaseService.handleConfirmCustomerDetailsError(err);
            }
            return EMPTY;
          })
        );

    const generateNewNumberCall$ =
      this.checkoutSessionService.isMBB && finishOrder ? this.checkoutDataAccessService.changeNewNumber() : of(null);

    const shouldUpdateEsim =
      this.checkoutSessionService.productSpecFamily === 'INTERNET' &&
      this.checkoutSessionService.productSpecClass === 'POSTPAID';

    const updateSIMTypeCall$ =
      shouldUpdateEsim && finishOrder
        ? this.checkoutDataAccessService.updateSIMType(this.checkoutDetailsService.activateEsim())
        : of(false);
    forkJoin([updateDetailsCall$, updateWishdateCall$, lineCheckByOtoIdCall$])
      .pipe(
        switchMap(([, , otoIdResponse]) => {
          this.cdr.markForCheck();
          if (otoIdResponse) {
            if (
              this.checkoutDetailsService.areAddressesEqual(
                (otoIdResponse as MsLineCheckService.LineCheckResp).installationAddress,
                this.checkoutSessionService.installationAddress
              )
            ) {
              this.checkoutBaseService.lineCheckRefId = (otoIdResponse as MsLineCheckService.LineCheckResp).refId;
              return this.callAddOtoId();
            } else {
              // throw error if addresses are not equal
              const error: Partial<HttpErrorResponse> = {
                url: '/otoid',
                error: 'ADDRESSES_NOT_MATCH',
              };
              return throwError(() => error);
            }
          }
          return of(false);
        }),
        switchMap(() => {
          const existingCustomerUpdatedMissingDataOnSelfcare = this.checkoutBaseService.updatedSelfcareData;
          const existingCustomerLoggedInButNotInSession = !this.checkoutSessionService.existingCustomer;
          if (existingCustomerUpdatedMissingDataOnSelfcare || existingCustomerLoggedInButNotInSession) {
            return addAccountIdCall$;
          }
          return of(null);
        }),
        switchMap(() => this.checkoutDetailsService.buildUpdateShipmentAddress(this.existingCustomer())),
        switchMap(() => confirmPersonalDetails$),
        switchMap(() => confirmPersonalId$),
        switchMap(() => generateNewNumberCall$),
        switchMap(() => updateSIMTypeCall$),

        tap(() => {
          if (this.checkoutDetailsService.scanIdNowOrLater) {
            this.checkoutBaseService.addAnalyticsEvent(`scan_id_${this.checkoutDetailsService.scanIdNowOrLater.value}`);
          }
          if (this.checkoutDetailsService.isFiber) {
            this.checkoutBaseService.addAnalyticsEvent('fiber_number_option', {
              fiber_option_selected: this.checkoutDetailsService.enterFiberNumberNowOrLater.value,
            });
          }
          if (finishOrder) {
            this.checkoutBaseService.finishOrder();
          } else {
            if (isMobileFirstStep) {
              if (!this.analyticsService.alreadyRegisteredOnDataLayer('checkout_mobile_next')) {
                this.checkoutBaseService.addAnalyticsEvent('checkout_mobile_next');
              }
            }
            this.flowService.handleNavigateToNext(this.stepPath);
          }
        }),
        catchError(err => {
          this.loadingService.hideLoading();
          this.cdr.markForCheck();
          if (!err?.wasCaught) {
            const isOtoIdError = err.url?.includes('/otoid');
            if (isOtoIdError) {
              return this.handleOtoIdError(err);
            }
            return this.checkoutBaseService.handleError(err);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  private callUpdateWishDate() {
    return this.checkoutBaseService.addLineCheck(formatDateToMsFormat(this.checkoutDetailsService.wishDate)).pipe(
      tap(() => {
        this.checkoutBaseService.addAnalyticsEvent('activation_date_selected');
      }),
      catchError(err => {
        if (!err?.wasCaught) {
          this.checkoutBaseService.handleError(err);
        }
        this.loadingService.hideLoading();
        this.cdr.markForCheck();
        return EMPTY;
      })
    );
  }

  private callAddOtoId() {
    // FIXME: Fix from MS, so we can omit wishDate
    return this.checkoutBaseService.addLineCheck(formatDateToMsFormat(this.checkoutDetailsService.wishDate)).pipe(
      tap(() => {
        this.checkoutBaseService.addAnalyticsEvent('fiber_number_added');
      }),
      catchError(err => {
        if (!err?.wasCaught) {
          this.checkoutBaseService.handleError(err);
        }
        this.loadingService.hideLoading();
        this.cdr.markForCheck();
        return EMPTY;
      })
    );
  }

  updateContactEmail(control: AbstractControl) {
    const previousValue: string | undefined =
      this.checkoutDetailsService.previousInvalidEmail() ?? this.checkoutSessionService.contactEmail;

    const value = control.value;
    if (control.valid && value !== previousValue) {
      this.checkoutDetailsService.validateEmail.set(true);
      this.checkoutDetailsService.email.disable();
      this.checkoutDetailsService.isEmailValidForBackend.set(false);
      this.checkoutBaseService
        .addEmail(value)
        .pipe(
          tap(() => {
            this.checkoutDetailsService.previousInvalidEmail.set(null);
            this.checkoutDetailsService.userNeedsToLogin.set({
              value: false,
              clickedNext: false,
            });
            this.checkoutDetailsService.isEmailValidForBackend.set(true);
            this.checkoutBaseService.addAnalyticsEvent(`email_submitted`);
            this.checkoutDetailsService.email.enable();
          }),
          catchError(err => {
            this.checkoutDetailsService.isEmailValidForBackend.set(false);
            this.checkoutDetailsService.previousInvalidEmail.set(value);
            this.checkoutDetailsService.email.enable();
            if (!err?.wasCaught) {
              if (err?.error?.error === CHECKOUT_ERRORS.ERR_EMAIL_EXISTS) {
                this.checkoutDetailsService.userNeedsToLogin.update(currentValue => {
                  return { ...currentValue, value: true };
                });
                return EMPTY;
              }
              if (err?.error?.error === CHECKOUT_ERRORS.ERR_INVALID_EMAIL) {
                this.checkoutDetailsService.email.setErrors({ email: true });
                this.checkoutDetailsService.userNeedsToLogin.update(currentValue => {
                  return { ...currentValue, value: false };
                });

                return EMPTY;
              }

              if (err.error.error === CHECKOUT_ERRORS.ERR_JSON_VALIDATION) {
                const formValidationErrors = this.checkoutBaseService.handleJsonValidationError(err.error.fields);
                this.checkoutDetailsService.markContactSectionAsInvalid(formValidationErrors);
                return EMPTY;
              }
            }
            this.checkoutDetailsService.email.setErrors({});
            this.checkoutBaseService.handleError(err);
            return EMPTY;
          }),
          finalize(() => {
            this.checkoutDetailsService.validateEmail.set(false);
            this.cdr.markForCheck();
          })
        )
        .subscribe();
    } else {
      this.checkoutBaseService.checkFormErrorToAddAnalytics('email', control);
    }
  }

  updateContactPhone(control: AbstractControl) {
    const fieldKey: keyof MsCheckoutService.PersonalDetails['contactDetail'] = 'phoneNumber';
    const previousValue: string | undefined = this.checkoutSessionService.contactPhoneNumber;

    const updatedValue = transformPhoneNumber(control.value);
    if (control.valid && updatedValue !== previousValue) {
      const personalDetails: MsCheckoutService.UpdateCustomer = {
        preferredLang: this.lang.current,
        title: this.checkoutDetailsService.title.value,
        contactDetail: { [fieldKey]: updatedValue },
      };
      if (!this.existingCustomer()) {
        this.checkoutBaseService
          .addCustomerDetails({ personalDetails })
          .pipe(
            tap(() => {
              this.checkoutBaseService.addAnalyticsEvent(`phone_submitted`);
            }),
            catchError(err => {
              if (!err?.wasCaught) {
                if (err.error.error === CHECKOUT_ERRORS.ERR_JSON_VALIDATION) {
                  const formValidationErrors = this.checkoutBaseService.handleJsonValidationError(err.error.fields);
                  this.checkoutDetailsService.markContactSectionAsInvalid(formValidationErrors);
                  return EMPTY;
                } else {
                  this.checkoutBaseService.handleError(err);
                }
              }
              return EMPTY;
            })
          )
          .subscribe();
      }
    } else {
      this.checkoutBaseService.checkFormErrorToAddAnalytics('phone', control);
    }
  }

  ngOnDestroy(): void {
    this.checkoutDetailsService.identificationForm.reset();
    this.checkoutDetailsService.contactDetailsForm.reset();
    this.checkoutDetailsService.userNeedsToLogin.set({
      value: false,
      clickedNext: false,
    });
    this.checkoutDetailsService.isEmailValidForBackend.set(true);
    this.checkoutDetailsService.phoneNumberInvalid.set(false);
  }

  private handleOtoIdError(err: HttpErrorResponse): Observable<never> {
    if (err.error === 'ADDRESSES_NOT_MATCH') {
      this.checkoutDetailsService.otoId.setErrors({ addressesDontMatch: true });
    } else {
      this.checkoutDetailsService.otoId.setErrors({ fiberLine: true });
    }
    this.checkoutDetailsService.otoId.markAsTouched();
    this.checkoutDetailsService.otoIdFieldFocus.set(true);
    this.cdr.markForCheck();
    return EMPTY;
  }
}
