import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { SvgComponent } from 'icon';

export interface ListItem {
  icon: string;
  title: string;
  checked?: boolean;
  buttonText: string;
  buttonAction: () => void;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lib-list-item',
  standalone: true,
  imports: [SvgComponent],
  templateUrl: './list-item.component.html',
})
export class ListItemComponent {
  item = input<ListItem>({
    icon: '',
    title: '',
    checked: false,
    buttonText: '',
    buttonAction() {
      return;
    },
  });
}
