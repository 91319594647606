<form [formGroup]="formGroup">
  <span class="flex text-black mb-5 section-title"> {{ ['checkout_details', 'fiber_title'] | translate }}</span>
  <lib-radio-button-form-field
    class="mb-2"
    [options]="fiberNumberRadioOptions"
    [vertical]="true"
    formControlName="enterFiberNumberNowOrLater"
    (onOptionChange)="toggleFiberNumberOption($event)"
  >
  </lib-radio-button-form-field>
  @if (enterFiberNumberNowOrLater.value === 'now') {
    <div>
      <lib-input-form-field
        (onBlur)="onFiberFormBlur('fiber number', otoId)"
        class="w-full"
        [label]="['checkout_details', 'fiber_nr_label'] | translate"
        [size]="8"
        formControlName="otoId"
        [placeholder]="['checkout_details', 'enter_fiber_number'] | translate"
        [clearInputSuffix]="'invalid'"
        [autoFocus]="autoFocus"
        [mask]="'S.000.000.000.A'"
        [maskCustomPatterns]="otoIdmaskCustomPatterns"
        [maskInputTransformFn]="otoIdinputTransformFn"
      >
      </lib-input-form-field>
      <h6 class="text-gray-1">
        {{ ['checkout_details', 'otoid_text_1'] | translate }}
        <a (click)="openOtoIdHelperModal()" class="font-bold text-primary cursor-pointer">
          {{ ['checkout_details', 'otoid_text_2'] | translate }}
        </a>
      </h6>
    </div>
  }
</form>
